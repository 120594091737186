import React, { useState } from "react";
import { Link } from "react-router-dom";
import { TextInput } from "../../components/TextInput.js/TextInput";
import Button from "../../components/Button/Button";
import { Divider } from "./../../components/Divider/Divider";
import styled from "styled-components";
import Facebook from "../../assets/images/facebook.png";
import Google from "../../assets/images/google-plus.png";
import Apple from "../../assets/images/apple.png";

import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { login, socialLogin } from "../../actions/auth";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import LostPassword from "../../components/LostPassword/LostPassword";
import { auth } from "../../utils/firebase";
import { useTrackPageView } from "../../hooks/trackPageView";

const RightDiv = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: 0 auto;
`;

const LogoWrapper = styled.div`
  display: flex;
`;

const P = styled.p`
  margin: 15px 0;
`;

const Register = styled(Link)`
  margin: 15px 0;
`;

const Login = (props) => {
  const [lostPassword, setLostPassword] = useState(false);
  const { register, handleSubmit, errors } = useForm();
  const { t } = useTranslation();

  useTrackPageView(props);
  const socialLogin = async (loginProvider) => {
    let provider = null;
    switch (loginProvider) {
      case "facebook":
        provider = new auth.FacebookAuthProvider();
        provider.addScope("public_profile");
        break;
      case "google":
        provider = new auth.GoogleAuthProvider();
        provider.addScope("https://www.googleapis.com/auth/userinfo.profile");
        break;
      case "apple":
        provider = new auth.OAuthProvider("apple.com");
        provider.addScope("email");
        provider.addScope("name");
        break;
      default:
        break;
    }
    props.socialLogin(provider);
  };

  const onSubmit = async (data) => {
    try {
      var { email, password } = data;
      props.login(email, password);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="wrapper">
      <div className="content-without-nav">
        <div className="container">
          <div className="div-register"></div>
          <div className="login-card">
            <RightDiv>
              <h1 style={{ color: "#444" }}>{t("login.title")}</h1>
              <Divider
                height={"2px"}
                width={"50%"}
                primary
                marginVert={"20px"}
              />
              <LogoWrapper>
                <div className="social-logo-box">
                  <img
                    name="facebook"
                    src={Facebook}
                    alt="Facebook Login"
                    onClick={(e) => socialLogin("facebook")}
                  />
                </div>

                <div className="social-logo-box">
                  <img
                    name="google"
                    src={Google}
                    alt="Google Login"
                    onClick={(e) => socialLogin("google")}
                  />
                </div>
                <div className="social-logo-box">
                  <img
                    name="apple"
                    src={Apple}
                    alt="Apple Login"
                    onClick={(e) => socialLogin("apple")}
                  />
                </div>
              </LogoWrapper>
              <P>{t("login.subtitle")}</P>
              <form onSubmit={handleSubmit(onSubmit)}>
                <TextInput
                  name="email"
                  placeholder={t("login.email")}
                  stacked
                  ref={register({
                    required: true,
                    pattern:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  })}
                />
                {errors.email && (
                  <span className="input-error">{t("login.emailError")}</span>
                )}
                <TextInput
                  name="password"
                  type="password"
                  placeholder={t("login.password")}
                  stacked
                  label={t("login.forgottPassword")}
                  onClickLabel={() => setLostPassword(true)}
                  ref={register({ required: true })}
                />
                {errors.password && (
                  <span className="input-error">
                    {t("login.passwordError")}
                  </span>
                )}
                <Button type="submit" primary loading={props.isLoading}>
                  {t("login.button")}
                </Button>
              </form>
              <Register to="/register">{t("login.noAccount")}</Register>
            </RightDiv>
          </div>
        </div>
      </div>
      <LostPassword open={lostPassword} close={() => setLostPassword(false)} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.auth.progress,
  };
};
Login.propTypes = {
  login: PropTypes.func.isRequired,
};
export default connect(mapStateToProps, { login, socialLogin })(Login);
