import React from "react";
import styled, { keyframes } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faInfo, faCheck } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { removeAlert } from "../../actions/alert";

// Toast message
const ToastHeader = styled.h1`
  margin: 0;
  padding: 0;
  font-size: 16px;
  text-align: left;
`;

const ToastText = styled.p`
  margin: 0;
  padding: 0;
  font-size: 14px;
  text-align: left;
`;

// Loadbar and animation
const load = keyframes`
  from { width: 0;z-index:1;}
    to {width: 100%;z-index:2}
`;

const ToastLoadBar = styled.div`
  position: absolute;
  align-self: flex-end;
  height: 5px;
  background-color: #0da6a0;
  border-bottom-left-radius: 8px;
  animation: ${load} 4s linear infinite reverse;
`;

const AlertItem = props => {
  var { id, alertType, header, msg } = props.data;

  var TypeIcon = "";
  switch (alertType) {
    case "error":
      TypeIcon = faTimes;
      break;
    case "info":
      TypeIcon = faInfo;
      break;
    case "success":
      TypeIcon = faCheck;
      break;
    default:
      TypeIcon = "unknown";
  }

  return (
    <div key={id} className={`alert alert-${alertType}`}>
      <div className="alert-body">
        <div className="alert-icon">
          <FontAwesomeIcon icon={TypeIcon} size="lg" />
        </div>
        <div className="alert-content">
          <ToastHeader>{header}</ToastHeader>
          <ToastText>{msg}</ToastText>
        </div>
        <div className="alert-action">
          <FontAwesomeIcon
            icon={faTimes}
            size="xs"
            color={"#666"}
            onClick={() => props.removeAlert(id)}
          />
        </div>
        <ToastLoadBar className="alert-loadbar" />
      </div>
    </div>
  );
};

export default connect(null, {
  removeAlert
})(AlertItem);
