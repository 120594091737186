import React, { useState } from "react";
import styled from "styled-components";
import { colors } from "./../../presets/index";
import ProgressiveImage from "./../ProgressiveImage/ProgressiveImage";
import Placeholder from "../../assets/images/placeholder.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faArrowCircleLeft,
  faArrowCircleRight,
  faShareAlt,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import { Userlist } from "../Userlist/Userlist";
import { useMediaQuery } from "react-responsive";

const H2 = styled.h2`
  text-align: center;
  color: #fff;
`;
const P = styled.p`
  text-align: center;
  color: #fff;
  padding: 0 20px;
`;

const IconHolder = styled.div`
  margin: 20px 0;
  display: flex;
  justify-content: center;

  > svg {
    margin: 0px 10px;
    cursor: pointer;
    border-radius: 50px;
    height: 50px;
    width: 50px !important;
    font-size: 12px;
    padding: 10px;
  }

  & svg:nth-child(1) {
    background-color: ${colors.primaryColor};
  }
  & svg:nth-child(2) {
    background-color: orange !important;
  }
`;

const Sidebar = (props) => {
  const isBigScreen = useMediaQuery({ query: "(min-device-width: 768px)" });

  const [state, setstate] = useState({
    expand: isBigScreen ? true : false,
    loading: true,
  });

  // useEffect(() => {
  //   if (props.users !== state.users) {
  //     setstate({ users: props.users });
  //   }
  // }, [props.users]);

  return (
    <div
      className={`sidebar ${
        state.expand ? "sidebar-expand" : "sidebar-closed"
      }`}
    >
      {JSON.stringify()}
      <div
        className="sidebar-icon"
        onClick={() => setstate({ expand: !state.expand })}
      >
        <FontAwesomeIcon
          icon={!state.expand ? faArrowCircleRight : faArrowCircleLeft}
          size="lg"
          color="white"
        />
      </div>
      <div className="sidebar-content">
        <ProgressiveImage
          style={{ minWidth: 250 }}
          preview={
            props.picture && props.picture !== "noimage"
              ? props.picture
              : Placeholder
          }
          image={
            props.picture && props.picture !== "noimage"
              ? props.picture
              : Placeholder
          }
        />
        <H2>{props.title}</H2>
        <P>{props.description}</P>
        <IconHolder>
          <FontAwesomeIcon
            icon={faShareAlt}
            size="lg"
            color="white"
            onClick={props.onClickShare}
          />
          {props.isAdmin && (
            <FontAwesomeIcon
              icon={faEdit}
              size="lg"
              color="white"
              onClick={props.onClickEdit}
            />
          )}
        </IconHolder>

        <Userlist users={props.users} />
      </div>
    </div>
  );
};

export default Sidebar;
