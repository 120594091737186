import React from "react";
import { CustomPicker } from "react-color";
import "./ColorPicker.css";

var { Saturation } = require("react-color/lib/components/common");
var { Hue } = require("react-color/lib/components/common");

class MyColorPicker extends React.Component {
  render() {
    return (
      <div>
        <div style={{ height: 20, position: "relative", margin: 20 }}>
          <Hue
            {...this.props}
            pointer={CustomPointer}
            direction={"horizontal" || "vertical"}
          />
          {/* <Saturation {...this.props} 
        
        /> */}
        </div>
        <div style={{ height: 20, position: "relative", margin: 20 }}>
          <Saturation {...this.props} />
        </div>
      </div>
    );
  }
}

const CustomPointer = (props) => {
  return <div className="custom-pointer" />;
};

export default CustomPicker(MyColorPicker);
