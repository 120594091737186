import React, { Component } from "react";
import Files from "react-files";
import styled from "styled-components";
import { Container, Row, Col } from "fluid-react";
import LazyImage from "../LazyImage/LazyImage";
import { Icon } from "semantic-ui-react";
import { colors } from "./../../presets/index";
import Button from "./../Button/Button";
import { withTranslation } from "react-i18next";

const Wrapper = styled.div`
  .files-dropzone-list {
    margin: 24px 48px 10px 48px;
    padding: 12px 0;
    text-align: center;
    box-sizing: border-box;
    border: 1px dashed #0da6a0;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .files-dropzone-active {
    padding: 80px 0;
  }
`;

const StyledCol = styled(Col)`
  margin-bottom: 12px;

  &:last-child {
    padding-right: 0px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 24px;

  > button {
    margin: 0 4px;
  }
`;

const ErrorDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 24px;

  > p {
    margin: 0 10px;
    text-align: center;
    color: red;
    font-size: 12px;
  }
`;

class FileUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      uploadFiles: props.uploadFiles,
      uploading: this.props.uploading,
      error: null,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  onFilesChange = (files) => {
    this.setState(
      {
        files,
      },
      () => {
        this.props.onDrop();
      }
    );
  };

  onFilesError = (error, file) => {
    this.setState({ error: error.message });
    console.log("error code " + error.code + ": " + error.message);
  };

  filesRemoveOne = (item) => {
    var files = this.state.files.filter((file) => file.id !== item.id);
    this.setState({ files: files });
  };

  filesRemoveAll = () => {
    this.setState({ files: [] }, this.refs.files.removeFiles());
    this.props.closeUpload();
  };

  renderImageList = () => {
    const { t } = this.props;
    return (
      <div>
        <Container>
          <Row>
            {this.state.files.map((file, index) => {
              var pictures = {
                full: file.preview.url,
                thumb: file.preview.url,
              };
              return (
                <StyledCol xs="12" sm="6" md="4" lg="4" xl="4" key={index}>
                  <LazyImage
                    style={{
                      padding: "0 10px",
                      minHeight: "50px",
                      minWidht: "90px",
                      objectFit: "cover",
                      cursor: "pointer",
                    }}
                    src={pictures.full}
                    preview={pictures.thumb}
                  />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    onClick={this.filesRemoveOne.bind(this, file)} // eslint-disable-line>
                  >
                    <Icon
                      name="close"
                      style={{ height: "inherit", color: "#0da6a0" }}
                    />

                    <span id={file.id}>Entfernen</span>
                  </div>
                </StyledCol>
              );
            })}
          </Row>
        </Container>
      </div>
    );
  };

  renderDropZone = () => {
    const { t } = this.props;
    return (
      <div style={{ display: "flex", flexDirection: "column", padding: 15 }}>
        <span>{t("selectImages.uploadImageText")}</span>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            cursor: "pointer",
            justifyContent: "center",
          }}
        >
          <Icon name="picture" color="purple" size="big" />
          <span
            style={{
              marginLeft: 4,
              color: colors.primaryColor,
              textDecoration: "underline",
            }}
          >
            {t("selectImages.uploadImageText2")}
            <br />
          </span>
          <span>{t("selectImages.uploadImageMultiselect")}</span>
        </div>
      </div>
    );
  };

  render() {
    const { t } = this.props;
    return (
      <Wrapper>
        <Files
          ref="files"
          className={
            this.state.files.length === 0
              ? "files-dropzone-list " + this.props.className
              : "files-dropzone-list"
          }
          onChange={this.onFilesChange}
          onError={this.onFilesError}
          maxFiles={10}
          maxFileSize={100000000}
          minFileSize={0}
          accepts={["image/*"]}
          clickable={this.state.files.length > 0 ? false : true}
        >
          {this.state.files.length > 0
            ? this.renderImageList()
            : this.renderDropZone()}
        </Files>
        {this.state.error && (
          <ErrorDiv style={{}}>
            <p>{this.state.error}</p>
          </ErrorDiv>
        )}
        <ButtonWrapper>
          <Button onClick={this.filesRemoveAll} roundAll>
            {t("selectImages.cancel")}
          </Button>
          <Button
            loading={this.state.uploading}
            onClick={() => {
              this.setState({ uploading: true }, () => {
                this.state.uploadFiles(this.state.files);
              });
            }}
            disabled={this.state.files.length === 0}
            roundAll
            primary
          >
            {t("selectImages.upload")}
          </Button>
        </ButtonWrapper>
      </Wrapper>
    );
  }
}

export default withTranslation()(FileUpload);
