import React from "react";
import styled, { css } from "styled-components";

const CARD = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.direction ? props.direction : "column")};
  padding: 24px 24px;
  border-radius: 8px;
  border: 1px solid #efefef;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);

  ${(props) =>
    props.centered &&
    css`
      align-items: center;
    `}
`;

export const Card = (props) => {
  return <CARD {...props}>{props.children} </CARD>;
};
