import { combineReducers } from "redux";
import alert from "./alert";
import auth from "./auth";
import event from "./event";
import images from "./images";
import liveEvent from "./liveEvent";

export default combineReducers({
  alertReducer: alert,
  auth: auth,
  events: event,
  images: images,
  liveEvent: liveEvent,
});
