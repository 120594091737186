import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "semantic-ui-css/semantic.min.css";
import "react-image-lightbox/style.css";
import "./assets/css/css-variables.scss";
import "./assets/css/scss-variables.scss";

import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { auth } from "./utils/firebase";

import { firestore, functions, storage } from "./utils/firebase";

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_KEY,
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.REACT_APP_STAGE,
  appVersion: process.env.REACT_APP_VERSIONSNUMBER,
  onError: function (event) {
    var userId = auth().currentUser.uid ?? "no-user"; // a custom user resolver
    event.setUser(userId);
  },
});
// functions().useEmulator("localhost", 5001);
// storage().useEmulator("localhost", 9199);
// firestore().useEmulator("localhost", 8080);

ReactDOM.render(<App />, document.getElementById("root"));
