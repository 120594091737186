import React, { useState, forwardRef } from "react";
import styled, { css } from "styled-components";
import { colors } from "./../../presets/index";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const Input = styled.input`
  border-radius: 4px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  padding: 10px 12px;
  outline: none;
  -webkit-box-sizing: border-box;
  -moz--moz-box-sizing: border-box;
  box-sizing: border-box;
  resize: none;

  ${(props) =>
    !props.disabled &&
    css`
      &:hover {
        border: solid 1px rgba(0, 0, 0, 0.4);
      }
    `}

  ${(props) =>
    props.active &&
    css`
      border: solid 1px ${colors.primaryColor};
    `};

  &:invalid {
    border: solid 1px ${colors.error};
  }
  ${(props) =>
    props.error &&
    css`
      border: solid 1px ${colors.error};
    `};

    &:disabled {
      border: solid 1px rgba(0,0,0,0.1)
      background-color: #f0f0f0;
    }
`;

const InputBox = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;

  > label {
    font-size: 12px;
    font-weight: bold;
    color: #242424;
    margin-bottom: 10px;
  }
`;

const IconBox = styled.div`
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  padding: 10px 12px;

  > input {
    width: 100%;
  }

  > input:focus {
    outline: none;
    border-bottom: 1px solid #efefef;
  }
  > svg {
    cursor: pointer;
    margin-left: 10px;
  }
`;

const LabelHeader = styled.div`
  display: flex;
  justify-content: space-between;

  > label {
    font-size: 12px;
    font-weight: bold;
    color: #242424;
    margin-bottom: 10px;
  }
`;

const LabelLink = styled.span`
  text-decoration: underline;
  color: #4183c4;
`;
export const TextInput = forwardRef((props, ref) => {
  const [formData, setFormData] = useState({
    value: props.value,
  });

  const onChange = (e) => {
    e.preventDefault();
    setFormData({ ...formData, value: e.target.value });
  };

  if (props.stacked) {
    const { placeholder, ...nProps } = props;
    return (
      <InputBox>
        {nProps.label ? (
          <LabelHeader>
            <label>{placeholder}</label>
            <LabelLink onClick={nProps.onClickLabel}>{nProps.label}</LabelLink>
          </LabelHeader>
        ) : (
          <label>{placeholder}</label>
        )}
        <Input ref={ref} onChange={onChange} {...nProps} />
      </InputBox>
    );
  }

  if (props.icon) {
    const { style, ...nProps } = props;

    return (
      <IconBox style={style}>
        <input ref={ref} {...nProps} />
        <FontAwesomeIcon icon={props.icon} onClick={props.onClick} />
      </IconBox>
    );
  }
  return <Input ref={ref} onChange={onChange} {...props} />;
});
