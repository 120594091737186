import React, { useState, forwardRef } from "react";
import styled, { css } from "styled-components";
import { colors } from "../../presets/index";

const TXTArea = styled(({ innerRef, ...props }) => {
  return <textarea ref={innerRef} {...props} />;
})`
  border-radius: 4px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  padding: 10px 12px;
  outline: none;
  -webkit-box-sizing: border-box;
  -moz--moz-box-sizing: border-box;
  box-sizing: border-box;
  resize: none;

  ${props =>
    !props.disabled &&
    css`
      &:hover {
        border: solid 1px rgba(0, 0, 0, 0.4);
      }
    `}

  ${props =>
    props.active &&
    css`
      border: solid 1px ${colors.primaryColor};
    `};

  &:invalid {
    border: solid 1px ${colors.error};
  }
  ${props =>
    props.error &&
    css`
      border: solid 1px ${colors.error};
    `};

    &:disabled {
      border: solid 1px rgba(0,0,0,0.1)
      background-color: #f0f0f0;
    }
`;

export const Textarea = forwardRef((props, ref) => {
  const [formData, setFormData] = useState({
    value: props.value
  });

  const onChange = e => {
    e.preventDefault();
    setFormData({ ...formData, value: e.target.value });
  };

  return (
    <TXTArea
      innerRef={ref}
      {...props}
      value={formData.value}
      onChange={onChange}
    >
      {props.children}
    </TXTArea>
  );
});
