import React from "react";
import "./Popup.scss";
import Button from "../Button/Button";

const Popup = ({ title, message, onTrueCall, onFalseCall }) => {
  return (
    <div className="popup-wrapper">
      <div className="popup-head">
        <h2>{title}</h2>
      </div>
      <div>
        <p>{message}</p>
      </div>
      <div className="popup-buttons">
        <Button primary onClick={onTrueCall}>
          Ja
        </Button>
        <Button outline onClick={onFalseCall}>
          Nein
        </Button>
      </div>
    </div>
  );
};

export default Popup;
