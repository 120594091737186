import React, { useEffect, useRef, useState } from "react";
import { Tab } from "semantic-ui-react";
import { colors } from "../../presets/index";
import { TextInput } from "../TextInput.js/TextInput";
import Button from "../Button/Button";
import CropImage from "../CropImage/CropImage";
import OppacityWrapper from "../OpaccityWrapper/OpaccityWrapper";
import helper from "./../../utils/helper";
import { auth } from "../../utils/firebase";
import { loadUser } from "./../../actions/auth";
import { connect } from "react-redux";

import Avatar from "../../assets/images/profil_placeholder.png";
import { useTranslation } from "react-i18next";
import { ClipLoader } from "react-spinners";
import { css } from "styled-components";

const spinnerOverride = css`
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 10px);
  transform: translate(-50%, -50%);
`;

const ProfilTab = ({ user, loadUser, changeTab }) => {
  const [image, setImage] = useState(null);
  const { t } = useTranslation();
  const [imageData, setImageData] = useState({});
  const inputRef = useRef();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setImage(user.picture);
  }, [user]);
  const uploadImage = () => {
    inputRef.current.click();
  };

  const handleImageSelect = (e) => {
    if (e.target.files && e.target.files[0]) {
      let reader = new FileReader();
      let file = e.target.files[0];
      reader.onloadend = () => {
        setImageData({
          ...imageData,
          image: reader.result,
          file: file,
          croppedImageUrl: null,
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const onSelectCrop = async (data) => {
    setImageData({
      file: null,
      croppedImageUrl: data.url,
    });

    setLoading(true);
    try {
      await helper.uploadProfilPicture(data.file, user.uid);
      loadUser(auth().currentUser);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(true);
    }
  };

  return (
    <Tab.Pane>
      {imageData.file ? (
        <OppacityWrapper>
          <div style={{ maxHeight: "50vh", maxWidth: "50vw" }}>
            <CropImage
              avatar
              className={"ReactCrop-Avatar"}
              src={imageData.image}
              onSelectCrop={onSelectCrop}
            />
          </div>
        </OppacityWrapper>
      ) : null}

      <div className="profil-tab">
        <div className="user-image">
          <div className="profil-image-wrapper">
            {loading && (
              <ClipLoader
                css={spinnerOverride}
                color={colors.primaryDark}
                size={20}
                className="image-loader"
              />
            )}
            <img
              alt="Profil"
              src={image}
              className="profil-image"
              onError={() => setImage(Avatar)}
              onLoad={() => setLoading(false)}
            />
          </div>

          <span
            style={{ color: colors.primaryColor }}
            onClick={() => uploadImage()}
          >
            {t("profil.uploadImage")}
          </span>
        </div>
        <div className="user-data">
          <h2> {t("profil.profilDetails")}</h2>
          <div className="input-label">
            <label> {t("profil.name")}</label>
            <TextInput value={user.fullName} disabled={true} />
          </div>
          <div className="input-label">
            <label> {t("profil.email")}</label>
            <TextInput value={user.email} disabled={true} />
          </div>
          <Button primary onClick={() => changeTab(1)}>
            {t("profil.editProfil")}
          </Button>
        </div>
      </div>
      <input ref={inputRef} type="file" onChange={handleImageSelect} hidden />
    </Tab.Pane>
  );
};

const mapStateToProps = (state) => {
  return { user: { ...state.auth.user } };
};

export default connect(mapStateToProps, {
  loadUser,
})(ProfilTab);
