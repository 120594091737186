import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import AlertItem from "./AlertItem";

const Alert = ({ alerts }) => {
  if (alerts && alerts.length > 0) {
    return (
      <div className="alert-container">
        {alerts.map(alert => {
          return <AlertItem key={alert.id} data={alert} />;
        })}
      </div>
    );
  }
  return null;
};

Alert.propTypes = {
  alerts: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
  alerts: state.alertReducer
});

export default connect(mapStateToProps, {})(Alert);
