import React, { Component } from "react";
import Lightbox from "react-image-lightbox";
import { connect } from "react-redux";
import PulseLoader from "react-spinners/PulseLoader";
import { css } from "styled-components";
import { ImageGrid } from "../components/ImageGrid/ImageGrid";
import ShareEvent from "../components/ShareEvent/ShareEvent";
import Sidebar from "../components/Sidebar/Sidebar";
import {
  downloadImages,
  getEvent,
  getEventImages,
  storageUploadImages,
  uploadImages,
} from "./../actions/event";
import AddImage from "./../components/AddImage/AddImage";
import Navbar from "./../components/Navbar/Navbar";
import { colors } from "./../presets/index";
import { firestore } from "./../utils/firebase";
import { withTranslation } from "react-i18next";
import OpaccityWrapper from "../components/OpaccityWrapper/OpaccityWrapper";
import EditEvent from "../components/EditEvent/EditEvent";
import ReactGA from "react-ga4";

class Event extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventId: props.match.params.id,
      title: "",
      images: [],
      imageUpload: false,
      lightBoxOpen: false,
      shareEventOpen: false,
      editEventOpen: false,
      photoIndex: 0,
      uploading: false,
      users: [],
      isLoading: props.isLoading || true,
      isAdmin: props.user.uid === props.event.adminId,
    };

    this.eventRef = firestore().collection("events").doc(this.state.eventId);
    this.imageRef = firestore()
      .collection("events")
      .doc(this.state.eventId)
      .collection("images");
    this.userRef = firestore()
      .collection("events")
      .doc(this.state.eventId)
      .collection("eUser");
    this.addImagesRef = null;
  }

  componentDidMount() {
    ReactGA.send({ hitType: "pageview", page: this.props.path });

    this.eventRef.onSnapshot((snapshot) => this.props.getEvent(snapshot));
    this.imageRef
      .orderBy("timestamp", "desc")
      .onSnapshot((snapshot) => this.props.getEventImages(snapshot));
    this.getUserList();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      const { isLoading, user, event } = this.props;
      this.setState({
        isLoading,
        isAdmin: user.uid === event.adminId,
      });
    }
  }

  getUserList = async () => {
    var usersRef = await firestore()
      .collection("events")
      .doc(this.state.eventId)
      .collection("eUser")
      .get();
    let users = usersRef.docs.map(async (doc) => {
      let tmpData = await doc.data();
      let userRef = await tmpData.userRef.get();
      let user = await userRef.data();
      user.isAdmin = tmpData.isAdmin;
      return user;
    });
    this.setState({ users: await Promise.all(users), loading: false });
  };

  showImageUpload = () => {
    this.setState({ imageUpload: true });
  };

  imageCallBack = (files) => {
    if (files) {
      this.props
        .storageUploadImages(
          this.state.eventId,
          files,
          this.imageRef,
          this.props.user.uid
        )
        .then(() => {
          this.addImagesRef && this.addImagesRef.clearImages();
          this.setState({ imageUpload: false, uploading: false });
        })
        .catch((error) => {
          console.log(error);
          this.addImagesRef.clearImages();
          this.setState({ uploading: false });
        });
    } else {
      this.setState({ imageUpload: false });
    }
  };

  onClickImage = (id) => {
    this.setState({ lightBoxOpen: true, photoIndex: id });
  };

  onClickShare = () => {
    this.setState({ shareEventOpen: !this.state.shareEventOpen });
  };

  onClickEdit = () => {
    this.setState({ editEventOpen: !this.state.editEventOpen });
  };

  onCloseShare = (event) => {
    if (event.target.id === "overlay" || event.target.id === "wrapper") {
      this.setState({ shareEventOpen: false });
    }
  };

  removeWrapper = (event) => {
    if (event.target.id === "overlay" || event.target.id === "wrapper") {
      this.setState({ editEventOpen: false });
    }
  };

  navigateToLive = () => {
    this.props.history.push({
      pathname: `${this.state.eventId}/live`,
    });
  };
  navigateToSelect = (method) => {
    var images = [];
    if (method === "download" || this.state.isAdmin) {
      images = this.props.images;
    } else {
      images = this.props.images.filter((image) => {
        return image.uid === this.props.user.uid;
      });
    }

    this.props.history.push({
      pathname: `${this.state.eventId}/select`,
      images,
      eventId: this.state.eventId,
      event: this.props.event,
      method,
    });
  };

  dismissEvent = () => {
    this.setState({ editEventOpen: false });
  };

  render() {
    var { images, t } = this.props;
    var { photoIndex } = this.state;

    const spinnerOverride = css`
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
    `;
    var isEmpty = images && images.length > 0;
    if (images) {
      var imageSrc = images.map((image) => {
        return image.normal;
      });
    }

    return (
      <div className="wrapper">
        {this.state.lightBoxOpen ? (
          <Lightbox
            mainSrc={imageSrc[photoIndex]}
            nextSrc={imageSrc[(photoIndex + 1) % images.length]}
            prevSrc={imageSrc[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => this.setState({ lightBoxOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              })
            }
          />
        ) : null}
        {this.state.imageUpload ? (
          <AddImage
            uploading={this.state.uploading}
            ref={(ref) => (this.addImagesRef = ref)}
            imageCallback={this.imageCallBack}
          />
        ) : null}
        {this.state.shareEventOpen ? (
          <ShareEvent
            data={{
              qr: {
                eventCode: this.props.event.eventCode,
                hash: this.props.event.password,
              },
              link: this.props.event.shareLink,
            }}
            closeWrapper={this.onCloseShare}
            closeShare={this.onClickShare}
          />
        ) : null}
        <Navbar
          title={
            this.props.event
              ? this.props.event.title
              : t("eventDetails.loading")
          }
          item={[
            {
              key: "delete",
              text: t("eventDetails.deleteImages"),
              icon: "delete",
              onClick: () => this.navigateToSelect("delete"),
            },
            // {
            //   key: "download",
            //   text: t("eventDetails.downloadImages"),
            //   icon: "download",
            //   onClick: () => this.navigateToSelect("download"),
            // },
            {
              key: "add",
              text: t("eventDetails.addImages"),
              icon: "add",
              onClick: () => this.showImageUpload(),
            },
            {
              key: "live",
              text: t("eventDetails.liveMode"),
              icon: "play",
              onClick: () => this.navigateToLive("live"),
            },
          ]}
        />
        <div className={`content content-event`}>
          <Sidebar
            {...this.props.event}
            users={this.state.users}
            isAdmin={this.state.isAdmin}
            onClickShare={this.onClickShare}
            onClickEdit={this.onClickEdit}
          />
          <div className={`container ${!isEmpty ? "container-event" : null}`}>
            {this.props.isLoading ? (
              <PulseLoader
                css={spinnerOverride}
                className={"event-loader"}
                color={colors.primaryColor}
                size={15}
              />
            ) : null}
            <ImageGrid
              images={images}
              style={{ padding: 48 }}
              onShowImageUpload={this.showImageUpload}
              onClickImage={this.onClickImage}
            />
          </div>
          {this.state.editEventOpen ? (
            <OpaccityWrapper onClick={this.removeWrapper}>
              <EditEvent
                event={this.props.event}
                onDismiss={this.dismissEvent}
              />
            </OpaccityWrapper>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    isLoading: state.events.loading || state.images.loading,
    event: state.events.currentEvent,
    images: state.images.images,
  };
};
export default connect(mapStateToProps, {
  getEvent,
  uploadImages,
  storageUploadImages,
  downloadImages,
  getEventImages,
})(withTranslation()(Event));
