export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const REGISTER_BEGIN = "REGISTER_BEGIN";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_BEGIN = "LOGIN_BEGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const GETEVENTS_BEGIN = "GETEVENTS_BEGIN";
export const GETEVENTS_SUCCESS = "GETEVENTS_SUCCESS";
export const GETEVENTS_FAIL = "GETEVENTS_FAIL";
export const GETEVENT_BEGIN = "GETEVENT_BEGIN";
export const GETEVENT_SUCCESS = "GETEVENT_SUCCESS";
export const GETEVENT_FAIL = "GETEVENT_FAIL";
export const CREATEEVENT_BEGIN = "CREATEEVENT_BEGIN";
export const CREATEEVENT_SUCCESS = "CREATEEVENT_SUCCESS";
export const CREATEEVENT_FAIL = "CREATEEVENT_FAIL";
export const UPDATEEVENT_BEGIN = "UPDATEEVENT_BEGIN";
export const UPDATEEVENT_SUCCESS = "UPDATEEVENT_SUCCESS";
export const UPDATEEVENT_FAIL = "UPDATEEVENT_FAIL";
export const JOINEVENT_BEGIN = "JOINEVENT_BEGIN";
export const JOINEVENT_SUCCESS = "JOINEVENT_SUCCESS";
export const JOINEVENT_FAIL = "JOINEVENT_FAIL";
export const GETIMAGES_BEGIN = "GETIMAGES_BEGIN";
export const GETIMAGES_SUCCESS = "GETIMAGES_SUCCESS";
export const GETIMAGES_FAIL = "GETIMAGES_FAIL";
export const ADDIMAGES_BEGIN = "ADDIMAGES_BEGIN";
export const ADDIMAGES_SUCCESS = "ADDIMAGES_SUCCESS";
export const ADDIMAGES_FAIL = "ADDIMAGES_FAIL";
export const DELETEIMAGES_BEGIN = "DELETEIMAGES_BEGIN";
export const DELETEIMAGES_SUCCESS = "DELETEIMAGES_SUCCESS";
export const DELETEIMAGES_FAIL = "DELETEIMAGES_FAIL";
