import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Dropdown, Icon } from "semantic-ui-react";
import { logout } from "../../actions/auth";
import Avatar from "../../assets/images/profil_placeholder.png";
import Button from "../Button/Button";
import "./Navbar.scss";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { compose } from "redux";

const Navbar = (props) => {
  const isBigScreen = useMediaQuery({ query: "(min-device-width: 768px)" });

  var { user, history } = props;
  const [image, setImage] = useState(user.picture);
  const { t } = useTranslation();

  useEffect(() => {
    setImage(user.picture);
  }, [user]);
  const profilMenu = [
    {
      key: "username",
      text: user.email,
    },
    {
      key: "profil",
      text: t("navbar.yourProfil"),
      icon: "user",
      onClick: () => navToProfil(),
    },
    {
      key: "user",
      text: t("navbar.logout"),
      icon: "sign-out",
      onClick: () => props.logout(),
    },
  ];

  const navToProfil = () => {
    history.push(`/profil`);
  };

  const trigger = (
    <img
      data-testid="navbar-icon"
      className="user-image"
      alt="Profil"
      src={image}
      onError={() => setImage(Avatar)}
    />
  );

  const actionTrigger = <Icon name="bars" size="big" />;

  const renderMenu = () => {
    if (isBigScreen) {
      if (props.item) {
        return props.item.map((item, index) => {
          return (
            <Button
              key={index}
              color="#fff"
              outline
              onClick={() => item.onClick()}
            >
              <Icon name={item.icon} />
              {item.text}
            </Button>
          );
        });
      }
    } else {
      if (props.item) {
        return (
          <Dropdown
            trigger={actionTrigger}
            options={props.item}
            pointing="top right"
            icon={null}
          />
        );
      }
    }
  };

  return (
    <div className="navbar" data-testid="navbar">
      <div className="navbar-icon">
        {history.location.pathname !== "/dashboard" && (
          <Icon
            name="angle left"
            size="big"
            onClick={history.goBack}
            style={{ cursor: "pointer" }}
          />
        )}
        <h2 data-testid="navbar-title">
          {props.title ? props.title : t("navbar.yourEvents")}
        </h2>
      </div>
      <div className="navbar-end">
        {renderMenu()}

        {props.icon ? props.icon : null}
        <Dropdown
          trigger={trigger}
          options={profilMenu}
          pointing="top right"
          icon={null}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default compose(
  withRouter,
  connect(mapStateToProps, { logout })
)(Navbar);
