import React, { useState } from "react";
import { Tab } from "semantic-ui-react";

import Button from "../Button/Button";
import { Form, Field } from "react-final-form";
import { auth } from "../../utils/firebase";
import { updateUser } from "../../actions/auth";
import { connect } from "react-redux";
import { setAlert } from "./../../actions/alert";
import helper from "../../utils/helper";
import OppacityWrapper from "../OpaccityWrapper/OpaccityWrapper";
import Popup from "./../Popup/Popup";
import { useTranslation } from "react-i18next";

const EditProfilTab = ({ user, updateUser, setAlert }) => {
  const [loadingEmail, setLoadingEmail] = useState(false);
  const [loadingPass, setLoadingPass] = useState(false);
  const [loadingName, setLoadingName] = useState(false);

  const [popup, setpopup] = useState(false);
  const { t } = useTranslation();

  //Opaccity Wrapper
  const clickWrapper = (event) => {
    if (event.target.id === "overlay" || event.target.id === "wrapper") {
      setpopup(false);
    }
  };

  // Change Methods
  const changeEmail = async (values) => {
    setLoadingEmail(true);
    try {
      var { email, password } = values;
      var currentMail = auth().currentUser.email;
      let result = await auth().signInWithEmailAndPassword(
        currentMail,
        password
      );
      if (result) {
        await auth().currentUser.updateEmail(email);
        updateUser({ uid: auth().currentUser.uid, email: email });
        setLoadingEmail(false);
      }
    } catch (err) {
      console.log(err);
      setLoadingEmail(false);
      var errMsg = helper.getFirebaseError(err.message, err.code);
      setAlert(t("error.error"), errMsg, "error");
    }
  };

  const changePassword = async (values) => {
    try {
      setLoadingPass(true);
      var { oldPassword, newPassword } = values;
      var email = auth().currentUser.email;

      let result = await auth().signInWithEmailAndPassword(email, oldPassword);

      if (result) {
        await auth().currentUser.updatePassword(newPassword);
        setLoadingPass(false);
        setAlert(
          t("profil.passwordChangedTitle"),
          t("profil.passwordChangedMessage"),
          "success"
        );
      }
    } catch (err) {
      console.log(err);
      var errMsg = helper.getFirebaseError(err.message, err.code);
      setLoadingPass(false);
      setAlert(t("error.error"), errMsg, "error");
    }
  };

  const changeName = async ({ name }) => {
    setLoadingName(true);
    try {
      updateUser({ uid: auth().currentUser.uid, fullName: name });
      setLoadingName(false);
    } catch (err) {
      setLoadingName(false);
      console.log(err);
    }
  };
  //Validators
  const required = (value) => (value ? undefined : t("error.required"));

  const email = (value) => {
    const re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;
    let valid = re.test(String(value).toLowerCase());
    return valid ? undefined : t("error.emailInvalid");
  };
  const composeValidators = (...validators) => (value) =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined
    );

  const deleteAccount = async () => {};

  return (
    <Tab.Pane>
      <div className="editprofil-tab">
        <h2>{t("profil.editProfil")}</h2>
        <div className="card">
          <Form
            onSubmit={changeName}
            initialValues={{ name: user.fullName }}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
              <form onSubmit={handleSubmit}>
                <p className="subline">{t("profil.editName")}</p>
                <Field name="name" validate={required}>
                  {({ input, meta }) => (
                    <div className="input-wrapper">
                      <input
                        {...input}
                        className="textInput"
                        type="text"
                        placeholder={t("profil.nameLabel")}
                      />
                      {meta.error && meta.touched && (
                        <span className="form-error">{meta.error}</span>
                      )}
                    </div>
                  )}
                </Field>

                <Button loading={loadingName} type="submit" primary>
                  {t("profil.editNameButton")}
                </Button>
              </form>
            )}
          />
        </div>
        <div className="card">
          <Form
            onSubmit={changeEmail}
            initialValues={{ email: "", password: "" }}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
              <form onSubmit={handleSubmit}>
                <p className="subline">{t("profil.editEmail")}</p>
                <Field
                  name="email"
                  validate={composeValidators(required, email)}
                >
                  {({ input, meta }) => (
                    <div className="input-wrapper">
                      <input
                        {...input}
                        className="textInput"
                        type="email"
                        placeholder={t("profil.emailLabel")}
                      />
                      {meta.error && meta.touched && (
                        <span className="form-error">{meta.error}</span>
                      )}
                    </div>
                  )}
                </Field>

                <Field name="password" validate={required}>
                  {({ input, meta }) => (
                    <div className="input-wrapper">
                      <input
                        {...input}
                        className="textInput"
                        type="password"
                        placeholder={t("profil.password")}
                      />
                      {meta.error && meta.touched && (
                        <span className="form-error">{meta.error}</span>
                      )}
                    </div>
                  )}
                </Field>

                <Button loading={loadingEmail} type="submit" primary>
                  {t("profil.editEmailButton")}
                </Button>
              </form>
            )}
          />
        </div>
        <div className="card">
          <Form
            onSubmit={changePassword}
            validate={(values) => {
              const errors = {};
              if (values.newPassword && values.newPassword.length < 6) {
                errors.newPassword = t("error.passwordToShort");
              }
              if (values.newPassword_confirm !== values.newPassword) {
                errors.newPassword_confirm = t("error.passwordDoesNotMatch");
              }
              return errors;
            }}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
              <form onSubmit={handleSubmit}>
                <p className="subline">{t("profil.editPassword")}</p>

                <Field name="oldPassword" validate={required}>
                  {({ input, meta }) => (
                    <div className="input-wrapper">
                      <input
                        {...input}
                        className="textInput"
                        type="password"
                        placeholder={t("profil.oldPassword")}
                      />
                      {meta.error && meta.touched && (
                        <span className="form-error">{meta.error}</span>
                      )}
                    </div>
                  )}
                </Field>

                <Field name="newPassword" validate={required}>
                  {({ input, meta }) => (
                    <div className="input-wrapper">
                      <input
                        {...input}
                        className="textInput"
                        type="password"
                        placeholder={t("profil.newPassword")}
                      />
                      {meta.error && meta.touched && (
                        <span className="form-error">{meta.error}</span>
                      )}
                    </div>
                  )}
                </Field>

                <Field name="newPassword_confirm" validate={required}>
                  {({ input, meta }) => (
                    <div className="input-wrapper">
                      <input
                        {...input}
                        className="textInput"
                        type="password"
                        placeholder={t("profil.newPasswordConfirm")}
                      />
                      {meta.error && meta.touched && (
                        <span className="form-error">{meta.error}</span>
                      )}
                    </div>
                  )}
                </Field>

                <Button loading={loadingPass} primary>
                  {t("profil.editPassword")}
                </Button>
              </form>
            )}
          />
        </div>
        {/* <Button
          style={{ marginTop: 10 }}
          color="red"
          onClick={() => setpopup(true)}
        >
          {t("profil.deleteAccount")}
        </Button> */}
      </div>
      {popup ? (
        <OppacityWrapper onClick={clickWrapper}>
          <Popup
            title={t("profil.deleteAccountTitle")}
            message={t("profil.deleteAccountMessage")}
            onTrueCall={deleteAccount}
            onFalseCall={() => setpopup(false)}
          />
        </OppacityWrapper>
      ) : null}
    </Tab.Pane>
  );
};

export default connect(null, {
  updateUser,
  setAlert,
})(EditProfilTab);
