import React, { useState, Fragment } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { colors } from "./../../presets/index";
import ProgressiveImage from "../ProgressiveImage/ProgressiveImage";
import Placeholder from "../../assets/images/placeholder.jpg";

const CARD = styled.div`
  flex: 0 0 calc(25% - 60px);
  border: 1px solid #ccc;
  flex-direction: ${(props) => (props.direction ? props.direction : "column")};
  border-radius: 8px;
  border: 1px solid #efefef;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  min-height: 100px;
  min-width: 100px;

  > img {
    max-width: 100%;
  }
  > div {
    padding: 10px;
  }
`;

const HoverOp = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.1);
  height: 100%;
  width: 100%;
  cursor: pointer;
  padding: 0px !important;
`;

export const EventCard = (props) => {
  const { data } = props;

  const [hover, setHover] = useState({ isActive: false });

  const setActive = (value) => {
    setHover({ ...hover, isActive: value });
  };

  if (data.title) {
    return (
      <CARD
        {...props}
        onMouseEnter={(e) => setActive(true)}
        onMouseLeave={(e) => setActive(false)}
      >
        {hover.isActive ? <HoverOp /> : null}

        <div className="event-card-image-wrapper">
          <ProgressiveImage
            className="event-card-image"
            preview={
              data.picture && data.picture !== "noimage"
                ? data.picture
                : Placeholder
            }
            image={
              data.picture && data.picture !== "noimage"
                ? data.picture
                : Placeholder
            }
          />
        </div>

        <div>
          <h4>{data.title}</h4>
          <span>Eventcode: {data.eventCode}</span>
        </div>
      </CARD>
    );
  } else {
    return (
      <Fragment>
        <CARD
          onMouseEnter={(e) => setActive(true)}
          onMouseLeave={(e) => setActive(false)}
          onClick={props.onClick}
          {...props}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {hover.isActive ? <HoverOp /> : null}

          <FontAwesomeIcon
            icon={faPlus}
            size="4x"
            color={colors.primaryColor}
          />
        </CARD>
      </Fragment>
    );
  }
};
