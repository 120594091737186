import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { colors } from "../../presets";
import { firestore } from "../../utils/firebase";
import { getEvent, handleEventImages } from "./../../actions/event";
import Slideicon from "./../../assets/images/slideshow.png";
import "./Liveshow.scss";
import Slideshow from "./Slideshow/Slideshow";
import { ToolTip } from "./ToolTip/ToolTip";

const quality = [
  {
    key: "normal",
    text: "Normal",
    param: "normal",
  },
  {
    key: "high",
    text: "Hoch",
    param: "uri",
  },
];

const Liveshow = ({ eId, images, handleEventImages }) => {
  const getImageRef = (id) =>
    firestore().collection("events").doc(id).collection("images");

  var layouts = [
    {
      key: 0,
      value: "slideshow",
      icon: Slideicon,
      layoutTemplate: {
        backgroundColor: colors.primaryColor,
        delay: 5,
        quality: quality[0],
      },
    },
    // {
    //   key: 1,
    //   value: "grid",
    //   icon: Grid,
    //   layoutTemplate: {
    //     backgroundColor: colors.primaryColor,
    //     highlight: true,
    //     delay: 3,
    //   },
    // },
  ];

  const [collapsed, setCollapsed] = useState(false);
  const [settings, setSettings] = useState({
    layout: layouts[0].key,
    ...layouts[0].layoutTemplate,
  });

  const [imageQue, setimageQue] = useState([]);
  const [imgs, setimages] = useState([]);
  const [startShow, setstartShow] = useState(false);

  useEffect(() => {
    const subscribe = getImageRef(eId)
      .orderBy("timestamp", "asc")
      .onSnapshot((snapshot) => {
        handleEventImages(snapshot.docs);
      });
    return () => subscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eId]);

  useEffect(() => {
    let isAdded = images.length > imgs.length;
    if (isAdded) {
      setimages(images);
    }
  }, [imgs, images]);
  // useEffect(() => {
  //   let isAdded = props.images.length >= images?.length;
  //   if (startShow && isAdded) {
  //     let newImages = differenceBy(props.images, images, "key");
  //     setimageQue(newImages);
  //   } else {
  //     setimages(props.images);
  //   }
  // }, [images, props.images, startShow]);

  const changeSettings = (newSettings) => {
    if (newSettings?.layout) {
      setSettings({
        ...newSettings,
        ...layouts[newSettings?.layout].layoutTemplate,
      });
    } else {
      setSettings({ ...settings, ...newSettings });
    }
  };

  // const updateImageGrid = (image) => {
  //   setimages([...images, image]);
  // };

  // const updateImageQue = (que) => {
  //   setimageQue([...que]);
  // };

  const renderShow = () => {
    switch (settings.layout) {
      case 0:
        return <Slideshow images={imgs} settings={settings} />;

      default:
        break;
    }
  };

  const setToolTipCollapsed = (value) => {
    setstartShow(value);
    setCollapsed(value);
  };

  return (
    <div className="new-liveshow" data-testid="liveshow">
      <ToolTip
        collapsed={collapsed}
        changeCollapsed={setToolTipCollapsed}
        layouts={layouts}
        settings={settings}
        onChangeSettings={changeSettings}
      />
      {renderShow()}
    </div>
  );
};

const mapStateToProps = (state) => {
  return { images: state.images.images };
};
export default connect(mapStateToProps, {
  getEvent,
  handleEventImages,
})(Liveshow);
