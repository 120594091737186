import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  LOGIN_BEGIN,
  REGISTER_BEGIN,
} from "../actions/Types";

const initialState = {
  isAuthenticated: null,
  progress: false,
  loading: true,
  user: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case REGISTER_BEGIN:
    case LOGIN_BEGIN:
      return { ...state, progress: true };
    case USER_LOADED:
      return Object.assign({}, state, {
        isAuthenticated: true,
        loading: false,
        user: payload,
      });

    case REGISTER_SUCCESS:
    case LOGIN_SUCCESS:
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        loading: false,
        progress: false,
      };

    case REGISTER_FAIL:
    case LOGIN_FAIL:
    case AUTH_ERROR:
    case LOGOUT:
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        user: null,
        progress: false,
      };

    default:
      return state;
  }
};
