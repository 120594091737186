import React from "react";
import { connect } from "react-redux";

import { Router, Route, Redirect, Switch } from "react-router-dom";

import Login from "./screens/auth/Login";
import Register from "./screens/auth/Register";
import Dashboard from "./screens/Dashboard";
import Event from "./screens/Event";
import Alert from "./components/Alert/Alert";
import PublicRoute from "./components/Auth/PublicRoute";
import PrivateRoute from "./components/Auth/PrivateRoute";
import { LoaderWrapper } from "./components/OpaccityWrapper/LoaderWrapper";
import SelectImages from "./screens/SelectImages";
import LiveShowScreen from "./screens/LiveShowScreen";
import history from "./history";
import Profil from "./screens/Profil";

const Root = (props) => {
  return (
    <Router history={history}>
      <Alert />
      <LoaderWrapper active={props.auth.loading} />
      <Switch>
        <PublicRoute exact path="/register" component={Register} />
        <PublicRoute exact path="/login" component={Login} />
        <PrivateRoute exact path="/dashboard" component={Dashboard} />
        <PrivateRoute exact path="/profil/" component={Profil} />
        <PrivateRoute exact path="/event/:id" component={Event} />
        <PrivateRoute path="/event/:id/select" component={SelectImages} />#
        {/* <PrivateRoute path="/event/:id/live" component={ImageShowWrapper} /> */}
        <PrivateRoute path="/event/:id/live" component={LiveShowScreen} />
        <PrivateRoute exact path="/profile" />
        <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
      </Switch>
    </Router>
  );
};

const mapStateToProps = (state) => {
  return { auth: state.auth };
};
export default connect(mapStateToProps, {})(Root);
