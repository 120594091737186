import React from "react";
import styled from "styled-components";
import Avatar from "../../assets/images/profil_placeholder.png";
const UL = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const LI = styled.li`
  margin: 5px 0px;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  padding: 10px 20px;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.3);
`;
const IMG = styled.img`
  object-fit: cover;
  margin-right: 20px;
  height: 35px;
  width: 35px;
  border-radius: 50px;
`;

export const Userlist = (props) => {
  return (
    <div>
      <UL>
        {(props.users &&
          props.users.map((user, index) => {
            return (
              <LI key={index}>
                <IMG
                  src={
                    user.picture && user.picture !== "noPicture"
                      ? user.picture
                      : Avatar
                  }
                />
                <span>{user.fullName}</span>
                {user.isAdmin ? <span>Admin</span> : <span>User</span>}
              </LI>
            );
          })) ||
          null}
      </UL>
    </div>
  );
};
