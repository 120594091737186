import React from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";

const PublicRoute = ({ component, auth, ...rest }) => {
  let ComponentToRender = component;

  const isAllowed = auth.isAuthenticated;
  if (auth.isAuthenticated !== null) {
    return isAllowed ? (
      <Redirect to="/dashboard" />
    ) : (
      <ComponentToRender {...rest} />
    );
  } else {
    return null;
  }
};

const mapStateToProps = (state, ownProps) => ({
  auth: state.auth,
});

export default withRouter(connect(mapStateToProps)(PublicRoute));
