import React, { Component } from "react";
import "./OpaccityWrapper.scss";

const MODAL_OPEN_CLASS = "modal-open";

export default class OppacityWrapper extends Component {
  componentDidMount() {
    document.body.classList.add(MODAL_OPEN_CLASS);
  }

  componentWillUnmount() {
    document.body.classList.remove(MODAL_OPEN_CLASS);
  }

  render() {
    return (
      <div className="opwrapper" id="overlay" onClick={this.props.onClick}>
        <div className="opwrapper-content" id="wrapper">
          {this.props.children}
        </div>
      </div>
    );
  }
}
