import React, { Component } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import styled from "styled-components";
import Button from "../Button/Button";

const CropWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const CropFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
`;

export default class CropImage extends Component {
  constructor(props) {
    super(props);

    var { avatar } = props;
    this.state = {
      src:
        props.src ||
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAARkAAAEBCAIAAADD90a2AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAAEnQAABJ0Ad5mH3gAAALASURBVHhe7dNBDQAwCACx+XeCOiwgYvdsUgt9Mwv8cwkaLkHDJWi4BA2XoOESNFyChkvQcAkaLkHDJWi4BA2XoOESNFyChkvQcAkaLkHDJWi4BA2XoOESNFyChkvQcAkaLkHDJWi4BA2XoOESNFyChkvQcAkaLkHDJWi4BA2XoOESNFyChkvQcAkaLkHDJWi4BA2XoOESNFyChkvQcAkaLkHDJWi4BA2XoOESNFyChkvQcAkaLkHDJWi4BA2XoOESNFyChkvQcAkaLkHDJWi4BA2XoOESNFyChkvQcAkaLkHDJWi4BA2XoOESNFyChkvQcAkaLkHDJWi4BA2XoOESNFyChkvQcAkaLkHDJWi4BA2XoOESNFyChkvQcAkaLkHDJWi4BA2XoOESNFyChkvQcAkaLkHDJWi4BA2XoOESNFyChkvQcAkaLkHDJWi4BA2XoOESNFyChkvQcAkaLkHDJWi4BA2XoOESNFyChkvQcAkaLkHDJWi4BA2XoOESNFyChkvQcAkaLkHDJWi4BA2XoOESNFyChkvQcAkaLkHDJWi4BA2XoOESNFyChkvQcAkaLkHDJWi4BA2XoOESNFyChkvQcAkaLkHDJWi4BA2XoOESNFyChkvQcAkaLkHDJWi4BA2XoOESNFyChkvQcAkaLkHDJWi4BA2XoOESNFyChkvQcAkaLkHDJWi4BA2XoOESNFyChkvQcAkaLkHDJWi4BA2XoOESNFyChkvQcAkaLkHDJWi4BA2XoOESNFyChkvQcAkaLkHDJWi4BA2XoOESNFyChkvQcAkaLkHDJWi4BA2XoOESNFyChkvQcAkaLkHDJWi4BA2XoOESNFyChkvQcAkaLkHDJWi4BA2XoOESNFyChkvQcAkaLkHDJWi4BA2XoOESNFyChkvQcAkaLkHDJWi4BA2XoOESNFyChktQmD1SsPePyw5giwAAAABJRU5ErkJggg==",
      croppedImageUrl: null,
      crop: avatar
        ? {
            unit: "%",
            width: 30,
            aspect: 1 / 1,
          }
        : {
            unit: "%",
            width: 30,
            aspect: 16 / 9,
          },
    };
    this.imageRef = null;
  }

  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    this.setState({ crop: crop });
  };

  makeClientCrop = async (crop) => {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({ croppedImageUrl: croppedImageUrl });
    }
  };

  getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = Math.ceil(crop.width * scaleX);
    canvas.height = Math.ceil(crop.height * scaleY);
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            console.error("Canvas is empty");
            return;
          }
          blob.name = fileName;
          window.URL.revokeObjectURL(this.fileUrl);
          var fileUrl = window.URL.createObjectURL(blob);
          resolve({ file: blob, url: fileUrl });
        },
        "image/jpeg",
        1
      );
    });
  };

  render() {
    var { src, crop, croppedImageUrl } = this.state;
    return (
      <CropWrapper style={this.props.style}>
        <ReactCrop
          className={this.props.className}
          circularCrop={this.props.avatar ? true : false}
          src={src}
          crop={crop}
          ruleOfThirds
          onImageLoaded={this.onImageLoaded}
          onComplete={this.onCropComplete}
          onChange={this.onCropChange}
        />
        <CropFooter>
          <Button
            primary
            onClick={() => this.props.onSelectCrop(croppedImageUrl)}
          >
            Zuschneiden
          </Button>
        </CropFooter>
      </CropWrapper>
    );
  }
}
