import {
  GETIMAGES_BEGIN,
  GETIMAGES_FAIL,
  GETIMAGES_SUCCESS,
  ADDIMAGES_BEGIN,
  ADDIMAGES_FAIL,
  ADDIMAGES_SUCCESS,
  LOGOUT,
  DELETEIMAGES_BEGIN,
  DELETEIMAGES_SUCCESS,
  DELETEIMAGES_FAIL,
} from "../actions/Types";

const initialState = {
  images: [],
  loading: true,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GETIMAGES_BEGIN:
      return { ...state, loading: true };
    case GETIMAGES_SUCCESS:
      return { ...state, images: payload, loading: false };
    case GETIMAGES_FAIL:
      return { ...state, loading: false };
    case DELETEIMAGES_BEGIN:
    case ADDIMAGES_BEGIN:
      return { ...state, loading: true };
    case ADDIMAGES_SUCCESS:
      return {
        ...state,
        images: [...payload],
        loading: false,
      };
    case DELETEIMAGES_SUCCESS:
    case DELETEIMAGES_FAIL:
    case ADDIMAGES_FAIL:
      return { ...state, loading: false };
    case LOGOUT:
      return { ...initialState };
    default:
      return state;
  }
};
