import axios from "axios";
import { storage } from "./firebase";
import i18n from "../services/i18n";

const helper = {
  getFirebaseError: function (message, code) {
    switch (code) {
      case "permission-denied":
        message = i18n.t("error.permissionDenied");
        break;
      case "auth/operation-not-allowed":
        message = i18n.t("error.notActivated");
        break;
      case "auth/wrong-password":
        message = i18n.t("error.wrongLogin");
        break;
      case "auth/user-not-found":
        message = i18n.t("error.userNotFound");
        break;
      case "auth/account-exists-with-different-credential":
      case "auth/email-already-in-use":
        message = i18n.t("error.emailAlreadyInUser");
        break;
      default:
        message = i18n.t("error.errorUnknown");
        break;
    }
    return message;
  },

  uploadImageAsync: async function (uri, path) {
    // Why are we using XMLHttpRequest? See:
    // https://github.com/expo/expo/issues/2402#issuecomment-443726662
    const blob = await new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function () {
        resolve(xhr.response);
      };
      xhr.onerror = function (e) {
        console.log(e);
        reject(new TypeError("Network request failed"));
      };
      xhr.responseType = "blob";
      xhr.open("GET", uri, true);
      xhr.send(null);
    });

    const ref = storage().ref(path);
    await ref.put(blob);

    // We're done with the blob, close and release it
    blob.close();
    return await ref.getDownloadURL();
  },
  uploadPicture: async function (file, path) {
    // Why are we using XMLHttpRequest? See:
    // https://github.com/expo/expo/issues/2402#issuecomment-443726662

    const ref = storage().ref(path);
    await ref.put(file);

    // We're done with the blob, close and release it
    return await ref.getDownloadURL();
  },

  uploadProfilPicture: async function (file, userId) {
    var api = process.env.REACT_APP_API_PROFILIMAGE;
    // var api = "/mymories-dev/us-central1/upload/profilImage";

    try {
      const config = {
        withCredentials: false,
        headers: { "Content-Type": "multipart/form-data" },
      };

      let data = new FormData();

      data.append("userId", userId);
      data.append("file", file);

      let result = await axios.post(api, data, config);
      return result;
    } catch (error) {
      console.log(error);
      return false;
    }
  },

  uploadEventPicture: async function (file, path, bucket, filename) {
    try {
      const config = {
        withCredentials: false,
        headers: { "Content-Type": "multipart/form-data" },
      };
      let data = new FormData();

      data.append("name", filename);
      data.append("bucket", bucket);
      data.append("file", file);

      var url = `${process.env.REACT_APP_API_PROFILIMAGE}/img`;

      let result = await axios.post(url, data, config);
      return result;
    } catch (error) {
      console.log("error happend");
      console.log(error);
      return false;
    }
  },
};

export default helper;
