import React, { useState } from "react";
import { Card } from "./../Card/Card";
import { TextInput } from "./../TextInput.js/TextInput";
import Button from "./../Button/Button";
import styled from "styled-components";
import { Image } from "semantic-ui-react";
import { Menu } from "semantic-ui-react";
import { Textarea } from "../Textare/Textare";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import { createEvent, joinEventByCode } from "../../actions/event";
import CropImage from "../CropImage/CropImage";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;

  > input,
  textarea {
    width: 100%;
    margin: 10px 0px;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 250px;
  max-width: 480px;
  background-color: #ccc;
`;

const Label = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;

  > span {
    font-weight: bold;
  }
`;

const CreateEvent = (props) => {
  const ref = React.createRef();

  const { t } = useTranslation();
  const ForwardInput = React.forwardRef((props, ref) => (
    <input
      type="file"
      id="file"
      ref={ref}
      style={{ display: "none" }}
      onChange={handleImageSelect}
    />
  ));

  const { register, handleSubmit, errors } = useForm();

  const [eventData, seteventData] = useState({
    tab: props.tab ? props.tab : "create",
    image: null,
    file: null,
    croppedImageUrl: null,
  });

  const onCreateEvent = async (data) => {
    var { title, password, description } = data;
    props
      .createEvent(title, password, description, eventData.file)
      .then(() => props.onDismiss())
      .catch((err) => console.log(err));
  };

  const onJoinEvent = async (data) => {
    var { eCode, jpassword } = data;
    props
      .joinEventByCode(eCode, jpassword)
      .then(() => props.onDismiss())
      .catch((err) => console.log(err));
    // props.login(email, password);
  };

  const handleItemClick = (active) => {
    seteventData({ ...eventData, tab: active });
  };

  const handleImageClick = (e) => {
    ref.current.click();
  };

  const handleImageSelect = (e) => {
    if (e.target.files && e.target.files[0]) {
      let reader = new FileReader();
      let file = e.target.files[0];
      reader.onloadend = () => {
        seteventData({
          ...eventData,
          image: reader.result,
          file: file,
          croppedImageUrl: null,
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const onSelectCrop = (props) => {
    seteventData({
      ...eventData,
      croppedImageUrl: props.url,
      file: props.file,
    });
  };

  return (
    <Card direction="column" style={{ backgroundColor: "#fff" }}>
      <Menu tabular>
        <Menu.Item
          name={t("dashboard.createEvent")}
          active={eventData.tab === "create"}
          onClick={() => handleItemClick("create")}
        />
        <Menu.Item
          name={t("dashboard.joinEvent")}
          active={eventData.tab === "join"}
          onClick={() => handleItemClick("join")}
        />
      </Menu>
      {eventData.tab === "create" ? (
        <Form onSubmit={handleSubmit(onCreateEvent)}>
          <ImageContainer>
            {eventData.file === null ? (
              <Label htmlFor="single">
                <FontAwesomeIcon
                  style={{ cursor: "pointer" }}
                  icon={faImage}
                  color="white"
                  size="5x"
                  onClick={handleImageClick}
                />
                <span>{t("dashboard.selectImage")}</span>
              </Label>
            ) : eventData.croppedImageUrl === null ? (
              <CropImage src={eventData.image} onSelectCrop={onSelectCrop} />
            ) : (
              <Image
                id="upload-image"
                name="upload-image"
                src={eventData.croppedImageUrl}
                width={"100%"}
                height={250}
                onClick={handleImageClick}
              />
            )}
            <ForwardInput ref={ref} />
          </ImageContainer>
          <TextInput
            txpe="text"
            placeholder={t("dashboard.name")}
            ref={register({ required: true })}
            name="title"
          />
          {errors.title && (
            <p className="input-error"> {t("dashboard.nameError")}</p>
          )}
          <TextInput
            placeholder={t("dashboard.password")}
            ref={register}
            name="password"
          />
          <Textarea
            type="text"
            name="description"
            placeholder={t("dashboard.desc")}
            ref={register}
          />
          <Button
            loading={props.loading}
            primary
            type="submit"
            disabled={eventData.file && eventData.croppedImageUrl == null}
          >
            {t("dashboard.createEvent")}
          </Button>
        </Form>
      ) : (
        <Form onSubmit={handleSubmit(onJoinEvent)}>
          <TextInput
            placeholder={t("dashboard.eventCode")}
            name="eCode"
            ref={register({ required: true })}
          />
          {errors.eCode && (
            <p className="input-error"> {t("dashboard.eventCodeError")}</p>
          )}
          <TextInput
            placeholder={t("dashboard.password")}
            ref={register}
            name="jpassword"
          />
          <Button primary loading={props.loading} type="submit">
            {t("dashboard.joinEvent")}
          </Button>
        </Form>
      )}
    </Card>
  );
};

const mapStateToProps = (state) => {
  return { loading: state.events.actionLoader || false };
};

export default connect(mapStateToProps, {
  createEvent,
  joinEventByCode,
})(CreateEvent);
