import React from "react";
import Masonry from "react-masonry-css";
import styled from "styled-components";
import { colors } from "./../../presets/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const ImageWrapper = styled.div`
  position: relative;
  margin-bottom: 5px;
`;

const Overlay = styled.div`
  border: 3px solid ${colors.primaryColor};
  padding: 10px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
`;
const breakpointColumnsObj = {
  default: 6,
  1200: 4,
  1100: 3,
  700: 2,
  500: 1,
};

const IMG = styled.img`
  max-width: 100%;
`;
export const SelectImageGrid = (props) => {
  return (
    <div style={{ overflow: "hidden" }}>
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {props.images.map((image, index) => {
          var found = props.selected.indexOf(image);
          if (found !== -1) {
            return (
              <ImageWrapper
                key={index}
                onClick={() => props.onClickImage(index)}
              >
                <Overlay>
                  <FontAwesomeIcon
                    icon={faCheck}
                    style={{ fontSize: 20 }}
                    color={"#00FF00"}
                  />
                </Overlay>
                <IMG src={image.normal} key={index} alt={image.alt} />
              </ImageWrapper>
            );
          } else {
            return (
              <img
                onClick={() => props.onClickImage(index)}
                src={image.normal}
                key={index}
                alt={image.alt}
              />
            );
          }
        })}
      </Masonry>
    </div>
  );
};
