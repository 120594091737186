import React, { useCallback, useEffect, useRef, useState } from "react";
import { SlideImage } from "./SlideImage.js/SlideImage";
import "./Slideshow.scss";
import ImageGallery from "react-image-gallery";
import "./Slideshow.scss";

const Slideshow = ({ images, settings }) => {
  const [sets, setSets] = useState(settings);
  const [imgs, setImgs] = useState([]);
  const [index, setIndex] = useState(0);
  const galleryRef = useRef(null);

  var GallerySettings = {
    showIndex: true,
    showBullets: false,
    showThumbnails: true,
    showPlayButton: false,
    showGalleryPlayButton: true,
    showNav: false,
    slideDuration: 500,
    slideInterval: settings.delay * 1000,
    thumbnailPosition: "left",
  };

  const reloadImages = useCallback(
    (setNew) => {
      let difference = [];
      if (setNew) {
        difference = images;
      } else {
        difference = images.filter(
          (img) => !imgs.find((x) => x.key === img.key)
        );
      }

      let newImages = difference.map((item) => {
        return {
          ...item,
          original: item?.[settings.quality.param],
          thumbnail: item?.normal,
        };
      });

      if (setNew) {
        setImgs([...newImages]);
      } else {
        setImgs((prevState) => [...prevState, ...newImages]);
      }
    },
    [images, imgs, settings]
  );

  useEffect(() => {
    if (images?.length > 0 && images.length !== imgs.length) {
      reloadImages(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images]);

  useEffect(() => {
    if (settings !== sets) {
      if (settings.quality.key !== sets.quality.key) {
        reloadImages(true);
      }
      galleryRef.current.pause();
      let button = document.getElementsByClassName("image-gallery-thumbnail");
      button[0].click();
      galleryRef.current.play();
      setSets(settings);
    }
  }, [reloadImages, sets, settings]);

  return (
    <div
      className="slideshow"
      style={{ backgroundColor: settings.backgroundColor }}
    >
      <ImageGallery
        {...GallerySettings}
        autoPlay={true}
        ref={galleryRef}
        items={imgs}
        startIndex={index}
        onSlide={(index) => setIndex(index)}
      />
    </div>
  );
};

export default Slideshow;
