import React from "react";
import "./ToolTip.scss";
import Button from "../../Button/Button";
import PropTypes from "prop-types";
import styled from "styled-components";

import MyColorPicker from "./ColorPicker/MyColorPicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";

import { Checkbox } from "semantic-ui-react";

const H2 = styled.h2`
  text-align: center;
  color: #242424;
`;

export const ToolTip = ({
  layouts,
  settings,
  collapsed,
  changeCollapsed,
  onChangeSettings,
}) => {
  const delaySlideArr = [3, 5, 10, 15, 20, 30, 45, 60, 90, 120, 180];
  const quality = [
    {
      key: "normal",
      text: "Normal",
      param: "normal",
    },
    {
      key: "high",
      text: "Hoch",
      param: "uri",
    },
  ];
  const delayPopupArr = [1, 2, 3, 4, 5, 8, 10];

  var entire_page = document.documentElement;

  function goFullScreen(element) {
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.msRequestFullscreen) {
      if (element === document.documentElement) {
        //check element
        element = document.body; //overwrite the element (for IE)
      }
      element.msRequestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullScreen) {
      element.webkitRequestFullScreen();
    } else {
      return; //if none are supported do not show message
    }
    document.body.classList.add("hideScroll");
    entire_page.scrollIntoView();
    changeCollapsed(true);
    //show user message (or something else)
  }

  function leaveFullScreen() {
    document.webkitExitFullscreen();
  }

  const renderCollapsed = () => (
    <div
      className="collapsed"
      onClick={() => {
        leaveFullScreen();
        changeCollapsed(false);
      }}
    >
      <FontAwesomeIcon
        className="collapsed-icon"
        style={{ cursor: "pointer" }}
        icon={faCog}
        color="black"
        size="2x"
      />
    </div>
  );

  const getAdditionalSettings = () => {
    const { layout } = settings;
    switch (layout) {
      case 0:
        return (
          <div>
            <div className="select-delay">
              <span style={{ color: "#242424" }}>
                Wartezeit pro Bild in Sekunden
              </span>
              <select
                value={settings?.delay}
                onChange={(e) =>
                  onChangeSettings({ delay: Number(e.target.value) })
                }
              >
                {delaySlideArr.map((delay, index) => (
                  <option
                    key={index}
                    value={delay}
                  >{`${delay} Sekunden`}</option>
                ))}
              </select>
            </div>
            <div className="select-delay">
              <span style={{ color: "#242424" }}>Bildqualität</span>
              <select
                value={settings?.quality.key}
                onChange={(e) => {
                  let item = quality.find((x) => x.key === e.target.value);
                  onChangeSettings({ quality: item });
                }}
              >
                {quality.map((quality, index) => (
                  <option key={index} value={quality.key}>
                    {quality.text}
                  </option>
                ))}
              </select>
            </div>
          </div>
        );
      case 1:
        return null;
      // <div className="select-delay">
      //   <Checkbox
      //     label={"Zeige neue Bilder als Popup"}
      //     defaultChecked={settings.highlight}
      //     type="checkbox"
      //     value={settings.highlight}
      //     onChange={(e) =>
      //       onChangeSettings({ highlight: !settings.highlight })
      //     }
      //   />

      //   {settings?.highlight && (
      //     <div className="popup-delay">
      //       <span style={{ color: "#242424" }}>
      //         Popupzeit pro Bild in Sekunden
      //       </span>
      //       <select
      //         value={settings?.delay}
      //         onChange={(e) =>
      //           onChangeSettings({ delay: Number(e.target.value) })
      //         }
      //       >
      //         {delayPopupArr.map((delay, index) => (
      //           <option
      //             key={index}
      //             value={delay}
      //           >{`${delay} Sekunden`}</option>
      //         ))}
      //       </select>
      //     </div>
      //   )}
      // </div>
      case 2:
        break;
      default:
        break;
    }
  };

  const renderToolTip = () => {
    return (
      <div className="tooltip">
        <H2>Slideshow Einstellungen</H2>
        <div className="select-layout">
          {layouts &&
            layouts?.map((item, index) => {
              return (
                <div
                  key={index}
                  className={`layout-item ${
                    settings.layout === item.key ? "active" : ""
                  }`}
                  onClick={() => onChangeSettings({ layout: item.key })}
                >
                  <img src={item.icon} />
                  <span>{item.value}</span>
                </div>
              );
            })}
        </div>

        <div className="select-color">
          <MyColorPicker
            color={settings?.backgroundColor}
            onChange={(color, event) =>
              onChangeSettings({ backgroundColor: color.hex })
            }
          />
        </div>
        {getAdditionalSettings()}
        <p>Klicken Sie hier um die Seite im Fullscreen zu öffnen</p>
        <div className="button-container">
          <Button primary onClick={() => goFullScreen(entire_page)}>
            Fullscreen
          </Button>
          <Button onClick={() => changeCollapsed(true)}>Abbrechen</Button>
        </div>
      </div>
    );
  };

  return collapsed ? renderCollapsed() : renderToolTip();
};

ToolTip.propTypes = {
  onChangeSettings: PropTypes.func.isRequired,
};
