import React from "react";
import styled, { css } from "styled-components";
import { colors } from "./../../presets/index";
import "./Button.css";
import ClipLoader from "react-spinners/ClipLoader";
import { PropTypes } from "prop-types";

export const BTN = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  border: none;
  border-radius: 6px;
  outline: none;
  font-size: 14px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);

  &:hover{
    background-color: #ddd;
  }

  ${(props) =>
    props.primary &&
    !props.disabled &&
    css`
      background-color: ${colors.primaryColor};
      color: #fff;

      :hover {
        background-color: ${colors.primaryDark};
      }
    `}
  

  ${(props) =>
    props.fab &&
    css`
      border-radius: 50px;
      height: 50px;
      width: 50px;
    `}

  ${(props) =>
    props.outline &&
    css`
      background-color: transparent;
      border: 1px solid #ddd;
      box-shadow: none;
    `}

  ${(props) =>
    props.primary &&
    props.outline &&
    css`
      /* background-color: transparent; */
      border: 1px solid ${colors.primaryColor};
      box-shadow: none;
      color: ${colors.primaryColor};

      :hover {
        color: #fff;
      }
    `}

  ${(props) =>
    props.color &&
    css`
      background-color: ${(props) => props.color};
      color: #fff;

      :hover {
        background-color: #fff;
        color: #000;
      }
    `}

  ${(props) =>
    props.color &&
    props.outline &&
    css`
      background-color: transparent;
      color: #fff;
      border: 1px solid ${(props) => props.color};
    `}
`;

const Button = (props) => {
  var { loading, ...nProps } = props;
  return (
    <BTN {...nProps}>
      {props.children}
      {props.loading ? (
        <ClipLoader color={"#fff"} size={20} css={{ marginLeft: 10 }} />
      ) : null}
    </BTN>
  );
};

Button.propTypes = {
  loading: PropTypes.bool,
};

export default Button;
