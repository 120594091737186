import React, { Component, Fragment } from "react";
import Navbar from "./../components/Navbar/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faTrash,
  faTimes,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { SelectImageGrid } from "../components/ImageGrid/SelectImageGrid";
import { downloadImages, deletePictures } from "./../actions/event";
import { connect } from "react-redux";
import history from "../history";
import { withTranslation } from "react-i18next";

import ClipLoader from "react-spinners/ClipLoader";
import ReactGA from "react-ga4";

class SelectImages extends Component {
  constructor(props) {
    super(props);
    const { t } = props;
    var { images, event, method, eventId } = props.location;

    this.state = {
      eventId,
      images,
      event,
      selected: [],
      selectedTitle: `0 ${t("selectImages.imagesSelected")}`,
      method,
      isDeleting: false,
      isDownloading: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    ReactGA.send({ hitType: "pageview", page: this.props.path });

    var { images } = this.props.imageState;
    if (images !== this.state.images) {
      this.setState({ images });
    }
  }

  onClickImage = (index) => {
    const { t } = this.props;
    var { selected, images } = this.state;

    var found = selected.indexOf(images[index]);
    if (found === -1) {
      selected.push(this.state.images[index]);
    } else {
      selected.splice(found, 1);
    }

    this.setState({
      selected,
      selectedTitle: t("selectImages.imageWithCountSelected", {
        count: selected.length,
      }),
    });
  };

  onImageAction = async (method) => {
    this.setState({ [method]: true });
    let result = null;
    switch (method) {
      case "isDeleting":
        result = await this.props.deletePictures(
          this.state.selected,
          this.state.eventId
        );
        break;
      case "isDownloading":
        result = await this.props.downloadImages();
        break;
    }
    this.setState({ [method]: false });
  };

  renderActions = () => {
    const { isDeleting, isDownloading, method } = this.state;
    let result = [];
    switch (method) {
      case "download":
        result.push(
          !isDownloading ? (
            <FontAwesomeIcon
              size="lg"
              icon={faDownload}
              onClick={() => this.onImageAction("isDownloading")}
            />
          ) : (
            <ClipLoader css={{ marginRight: 10 }} size={20} color={"#fff"} />
          )
        );
        break;
      case "delete":
        result.push(
          !isDeleting ? (
            <FontAwesomeIcon
              size="lg"
              icon={faTrash}
              onClick={() => this.onImageAction("isDeleting")}
            />
          ) : (
            <ClipLoader css={{ marginRight: 10 }} size={20} color={"#fff"} />
          )
        );
        break;
    }
    result.push(
      <FontAwesomeIcon size="lg" icon={faTimes} onClick={history.goBack} />
    );
    return result;
  };

  render() {
    return (
      <div className="wrapper">
        <Navbar
          title={this.state.selectedTitle}
          icon={<Fragment>{this.renderActions(this.state.method)}</Fragment>}
        />
        <div className={`content content-event`}>
          <div className={`container`}>
            <SelectImageGrid
              images={this.state.images || []}
              selected={this.state.selected}
              style={{ padding: 48 }}
              onClickImage={this.onClickImage}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { imageState: state.images };
};

export default connect(mapStateToProps, {
  downloadImages,
  deletePictures,
})(withTranslation()(SelectImages));
