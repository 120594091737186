import axios from "axios";
import { reject } from "lodash";
import md5 from "md5";
import uuid4 from "uuid4";
import { routes } from "../presets/index";
import eventHandler from "../utils/eventHandler";
import helper from "../utils/helper";
import { auth, firestore, functions, storage } from "./../utils/firebase";
import { setAlert } from "./alert";
import {
  GETEVENTS_FAIL,
  GETEVENTS_SUCCESS,
  GETEVENT_BEGIN,
  GETEVENT_FAIL,
  GETEVENT_SUCCESS,
  GETIMAGES_BEGIN,
  GETIMAGES_FAIL,
  GETIMAGES_SUCCESS,
  ADDIMAGES_BEGIN,
  ADDIMAGES_FAIL,
  ADDIMAGES_SUCCESS,
  JOINEVENT_BEGIN,
  CREATEEVENT_SUCCESS,
  CREATEEVENT_BEGIN,
  CREATEEVENT_FAIL,
  JOINEVENT_FAIL,
  DELETEIMAGES_BEGIN,
  DELETEIMAGES_SUCCESS,
  DELETEIMAGES_FAIL,
  JOINEVENT_SUCCESS,
  UPDATEEVENT_BEGIN,
  UPDATEEVENT_SUCCESS,
  UPDATEEVENT_FAIL,
} from "./Types";
import i18n from "../services/i18n";

const eventsNode = firestore().collection("events");

export const getEvent = (snap) => async (dispatch) => {
  try {
    dispatch({
      type: GETEVENT_BEGIN,
    });
    var eventData = snap.data();
    eventData.id = snap.id;
    dispatch({
      type: GETEVENT_SUCCESS,
      payload: eventData,
    });
  } catch (error) {
    dispatch(
      setAlert(
        i18n.t("eventReducer.titleLoadingEventFailure"),
        i18n.t("eventReducer.messageLoadingEventFailure"),
        "error"
      )
    );

    dispatch({
      type: GETEVENT_FAIL,
    });
  }
};

export const updateEvent = (eId, data) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      dispatch({
        type: UPDATEEVENT_BEGIN,
      });

      let result = await functions().httpsCallable("events-updateEvent")({
        eId,
        ...data,
      });

      const { event } = result.data;
      eventHandler.updateEvent(event.id, { ...event });
      dispatch({
        type: UPDATEEVENT_SUCCESS,
      });
      dispatch(
        setAlert(
          i18n.t("editEvent.updateSuccessTitle"),
          i18n.t("editEvent.updateSuccessMessage"),
          "success"
        )
      );
      resolve();
    } catch (error) {
      let errMsg = helper.getFirebaseError(error.message, error.code);
      dispatch({
        type: UPDATEEVENT_FAIL,
      });
      dispatch(
        setAlert(i18n.t("editEvent.updateFailureTitle"), errMsg, "error")
      );
      reject();
    }
  });
};

export const updateEventPass = (eId, data) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      dispatch({
        type: UPDATEEVENT_BEGIN,
      });

      let result = await functions().httpsCallable(
        "events-updateEventPassword"
      )({
        eId,
        ...data,
      });
      const { event } = result.data;
      eventHandler.updateEvent(event.id, { ...event });
      dispatch({
        type: UPDATEEVENT_SUCCESS,
      });
      dispatch(
        setAlert(
          i18n.t("editEvent.updatePassSuccessTitle"),
          i18n.t("editEvent.updatePassSuccessMessage"),
          "success"
        )
      );
      resolve();
    } catch (error) {
      const errMsg = helper.getFirebaseError(error.message, error.code);
      dispatch({
        type: UPDATEEVENT_FAIL,
      });
      dispatch(
        setAlert(i18n.t("editEvent.updatePassFailureTitle"), errMsg, "error")
      );
      reject();
    }
  });
};

export const getEventImages = (snap) => async (dispatch) => {
  try {
    dispatch({
      type: GETIMAGES_BEGIN,
    });

    var images = [];
    snap.forEach((doc) => {
      const { timestamp, uid, uri, normal, preview } = doc.data();
      images.push({
        key: doc.id,
        uuid: doc.data().uuid,
        doc, // DocumentSnapshot
        uri: uri,
        normal,
        preview,
        timestamp,
        uid: uid,
      });
    });

    dispatch({
      type: GETIMAGES_SUCCESS,
      payload: images,
    });
  } catch (error) {
    dispatch(
      setAlert(
        i18n.t("eventReducer.titleLoadingImagesFailure"),
        i18n.t("eventReducer.messageLoadingImagesFailure"),
        "error"
      )
    );

    dispatch({
      type: GETIMAGES_FAIL,
    });
  }
};

export const handleEventImages = (docs) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADDIMAGES_BEGIN,
    });

    const { images } = getState().images;

    let imageProm = docs.map(async (doc) => {
      const { timestamp, uid, uuid, uri, normal, preview } = doc.data();
      let ownerRef = await firestore().collection("users").doc(uid).get();
      let owner = ownerRef.data();

      return {
        key: doc.id,
        uuid: uuid,
        doc, // DocumentSnapshot
        uri: uri,
        normal,
        preview,
        timestamp,
        uid: uid,
        owner,
      };
    });

    let allImages = await Promise.all(imageProm);

    dispatch({
      type: ADDIMAGES_SUCCESS,
      payload: allImages,
    });
  } catch (error) {
    console.log(error);
    dispatch(
      setAlert(
        "Fehler beim Laden der Bilder",
        "Die Bilder konnten nicht geladen werden",
        "error"
      )
    );

    dispatch({
      type: ADDIMAGES_FAIL,
    });
  }
};

export const getEvents = (events, querySnapshot) => async (dispatch) => {
  try {
    var eventSnaps = [];
    if (!querySnapshot.empty) {
      var userEvents = querySnapshot.docs.map(async (doc) => {
        const data = await doc.data();
        const visited = data.visited;
        return { data, visited, eventRef: data.eventRef };
      });

      userEvents = await Promise.all(userEvents);

      var eventNodes = userEvents.map(async (doc) => {
        var userRef = await doc.eventRef.get();
        var eventSnap = await doc.eventRef.onSnapshot((snap) =>
          updateSingleEvent(snap, events)
        );
        eventSnaps.push(eventSnap);
        return { data: doc.data, userRef };
      });

      eventNodes = await Promise.all(eventNodes);

      var result = eventNodes.map((snap) => {
        var { data, userRef } = snap;

        const id = snap.userRef.id;
        let eventData = userRef.data();
        let object = {
          id: id,
          visited: data.visited,
          ...eventData,
        };
        return object;
      });
      var activeEvents = result.filter((event) => {
        if (event.status !== "deleted") return event;
      });
      dispatch({
        type: GETEVENTS_SUCCESS,
        payload: activeEvents.sort(function (x, y) {
          return y.lastUpdated - x.lastUpdated;
        }),
      });
    } else {
      dispatch({
        type: GETEVENTS_FAIL,
      });
    }
  } catch (error) {
    dispatch({
      type: GETEVENTS_FAIL,
    });
  }
  return eventSnaps;
};

const updateSingleEvent = (item, events) => async (dispatch) => {
  var itemData = item.data();
  if (itemData) {
    if (itemData.status === "deleted") {
      events = events.filter((event) => event.eventCode !== itemData.eventCode);
    } else {
      var position = events.findIndex(
        (event) => event.eventCode === itemData.eventCode
      );
      if (position === -1) {
        events.push(itemData);
      } else {
        Object.keys(events[position]).map((key) => {
          if (itemData.hasOwnProperty(key))
            events[position][key] = itemData[key];
        });
      }
    }
    dispatch({
      type: GETEVENTS_SUCCESS,
      payload: events,
    });
    //this.setState({ events: events });
  }
};

export const createEvent = (title, password, description, image) => async (
  dispatch
) => {
  return new Promise(async (resolve, reject) => {
    dispatch({
      type: CREATEEVENT_BEGIN,
    });

    try {
      var eventData = {
        title,
        password: password !== "" ? md5(password).toString() : "",
        description,
        picture: image,
      };

      await eventHandler.createEventService(eventData);

      dispatch(
        setAlert(
          i18n.t("eventReducer.titleCreateEventSuccess"),
          i18n.t("eventReducer.messageCreateEventSuccess"),
          "success"
        )
      );
      dispatch({ type: CREATEEVENT_SUCCESS });
      resolve();
    } catch (err) {
      dispatch({ type: CREATEEVENT_FAIL });
      reject();
    }
  });
};

export const joinEventByCode = (eCode, password) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch({
      type: JOINEVENT_BEGIN,
    });

    try {
      var hash = password !== "" ? md5(this.state.password) : "";
      await eventHandler.joinEventById(eCode, hash);
      dispatch(
        setAlert(
          i18n.t("eventReducer.titleRegisterEventSuccess"),
          i18n.t("eventReducer.messageRegisterEventSuccess"),
          "success"
        )
      );
      dispatch({
        type: JOINEVENT_SUCCESS,
      });
      resolve();
    } catch (err) {
      dispatch({ type: JOINEVENT_FAIL });
      dispatch(
        setAlert(i18n.t("eventReducer.titleRegisterEventFailure"), err, "error")
      );
      reject();
    }
  });
};

export const storageUploadImages = (eid, images, ref, uid) => async (
  dispatch
) => {
  var imageProm = images.map((image) => {
    return new Promise((resolve, reject) => {
      var { extension, type } = image;

      var uuid = uuid4();
      const uploadTask = storage()
        .ref()
        .child(`events/${eid}/${uuid}.${extension}`)
        .put(image);
      uploadTask.on(
        storage.TaskEvent.STATE_CHANGED,
        (snapshot) => {
          // const progress =
          //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          // if (snapshot.state === storage.TaskState.RUNNING) {
          // }
        },
        (error) => {
          console.log(error.code);
          reject();
        },
        async () => {
          resolve(await createThumbnails(eid, uuid, uid, type, extension));
        }
      );
    });
  });
  let result = await Promise.all(imageProm);
  let success = result.filter((item) => item.success === true);
  if (success.length > 1)
    dispatch(
      setAlert(
        i18n.t("eventReducer.titleImageUpload"),
        i18n.t("eventReducer.imageUploadedSuccess", { count: success.length }),
        "success"
      )
    );
  let failure = result.filter((item) => item.success !== true);
  if (failure.length > 1)
    dispatch(
      setAlert(
        i18n.t("eventReducer.titleImageUploadFailure"),
        i18n.t("eventReducer.imageUploadedSuccessFailure", {
          count: failure.length,
        }),
        "error"
      )
    );
};

async function createThumbnails(eId, fileName, uid, mimeType, ext) {
  try {
    let result = await functions().httpsCallable("directUpload")({
      event: eId,
      uuid: fileName,
      uid,
      mimeType: mimeType,
      ext: ext,
    });
    return { success: true };
  } catch (error) {
    console.log(error);
    return { success: false };
  }
}

export const uploadImages = (eid, images, ref, uid) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    //Now convert the blob to a wav file or whatever the type you want
    try {
      if (images) {
        images.map(async (image) => {
          var uuid = uuid4();
          const path = `events/${eid}/${uuid}.jpg`;
          const bucket = `events/${eid}`;
          let result = await helper.uploadEventPicture(
            image,
            path,
            bucket,
            uuid
          );
          var { images } = result.data;
          var node = {
            uri: images.full,
            preview: images.thumb,
            normal: images.normal,
            uid: uid,
            timestamp: Date.now(),
            uuid: uuid,
          };

          ref.add(node);

          return node;
        });
      }

      dispatch(
        setAlert(
          i18n.t("eventReducer.titleImageUpload"),
          i18n.t("eventReducer.imageUploadedSuccess", { count: images.length }),
          "success"
        )
      );
      //dispatch(getEvent(eid));

      resolve();
    } catch (err) {
      dispatch(
        setAlert(
          i18n.t("eventReducer.titleImageUploadFailure"),
          i18n.t("eventReducer.imageUploadedSuccessFailure", {
            count: images.length,
          }),
          "error"
        )
      );

      reject();
    }
  });
};

export const downloadImages = (eid) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios({
        url: `${routes.event}/${eid}/download`,
        method: "GET",
        responseType: "blob", // important
      }).then((response) => {
        let headerLine = response.headers["content-disposition"];
        let startFileNameIndex = headerLine.indexOf('"') + 1;
        let endFileNameIndex = headerLine.lastIndexOf('"');
        let filename = headerLine.substring(
          startFileNameIndex,
          endFileNameIndex
        );

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
      });

      dispatch(
        setAlert(
          i18n.t("eventReducer.titleDownloadImages"),
          i18n.t("eventReducer.messageDownloadImages"),
          "success"
        )
      );
      resolve();
    } catch (err) {
      dispatch(setAlert("", "", "error"));
      reject();
    }
  });
};

export const deletePictures = (images, eventId) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch({
      type: DELETEIMAGES_BEGIN,
    });
    try {
      var imageProm = images.map(
        async (image) => await deletePicture(eventId, image.key)
      );

      let result = await Promise.all(imageProm);
      let success = result.filter((item) => item === true);
      let failure = result.filter((item) => item !== true);

      if (success.length > 0) {
        dispatch(
          setAlert(
            i18n.t("eventReducer.titleDeleteImagesSuccess"),
            i18n.t("eventReducer.deleteImagesSuccess", {
              count: images.length,
            }),
            "success"
          )
        );
      }

      if (failure.length > 0) {
        dispatch(
          setAlert(
            i18n.t("eventReducer.titleDeleteImagesFailure"),
            i18n.t("eventReducer.messageDeleteImagesFailure"),
            "error"
          )
        );
      }

      dispatch({
        type: DELETEIMAGES_SUCCESS,
      });
      resolve();
    } catch (error) {
      dispatch(
        setAlert(
          i18n.t("eventReducer.titleDeleteImagesFailure"),
          i18n.t("eventReducer.messageDeleteImagesFailure"),
          "error"
        )
      );
      dispatch({
        type: DELETEIMAGES_FAIL,
      });
      reject();
    }
  });
};

async function deletePicture(eid, imageId) {
  try {
    let doc = await eventsNode.doc(eid).collection("images").doc(imageId);
    var { uuid } = await (await doc.get()).data();

    Promise.all([
      await deleteFileFromStorage(`events/${eid}/`, `${uuid}.jpg`),
      await deleteFileFromStorage(`events/${eid}/`, `${uuid}-thumbx1000.jpg`),
      await deleteFileFromStorage(`events/${eid}/`, `${uuid}-thumbx50.jpg`),
      await doc.delete(),
    ]);

    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

async function deleteFileFromStorage(pathToFile, fileName) {
  try {
    const ref = await storage().ref(pathToFile);
    const childRef = ref.child(fileName);
    return await childRef.delete();
  } catch (error) {
    console.log(error);
  }
}

function str2bytes(str) {
  var bytes = new Uint8Array(str.length);
  for (var i = 0; i < str.length; i++) {
    bytes[i] = str.charCodeAt(i);
  }
  return bytes;
}
