import React, { useState } from "react";
import { Card } from "./../Card/Card";
import { TextInput } from "./../TextInput.js/TextInput";
import Button from "./../Button/Button";
import styled from "styled-components";
import { Divider, Image } from "semantic-ui-react";
import { Textarea } from "../Textare/Textare";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import { updateEvent, updateEventPass } from "../../actions/event";
import CropImage from "../CropImage/CropImage";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import helper from "../../utils/helper";
import { auth } from "../../utils/firebase";
const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;

  > input,
  textarea {
    width: 100%;
    margin: 10px 0px;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 250px;
  max-width: 480px;
  background-color: #ccc;
  position: relative;
`;

const ImageClickWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  right: 0;
  justify-content: center;
  align-items: center;
  z-index: 2;
`;

const Label = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;

  > span {
    font-weight: bold;
  }
`;

const EditEvent = ({
  event: { id, title, description, picture, loading, password },
  updateEvent,
  updateEventPass,
  onDismiss,
}) => {
  const ref = React.createRef();
  const { t } = useTranslation();

  const [eventData, seteventData] = useState({
    picture,
    file: null,
    croppedImageUrl: null,
  });

  const ForwardInput = React.forwardRef((props, ref) => (
    <input
      type="file"
      id="file"
      ref={ref}
      style={{ display: "none" }}
      onChange={handleImageSelect}
    />
  ));

  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      title,
      picture,
      description,
    },
  });

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    watch: watch2,
    errors: errors2,
  } = useForm({
    defaultValues: {
      title,
      picture,
      description,
    },
  });

  const handleImageClick = (e) => {
    ref.current.click();
  };

  const onChangeEvent = (data) => {
    updateEvent(id, data).then(() => onDismiss());
  };

  const onChangeEventPass = (data) => {
    const { oldPassword, newPassword } = data;
    updateEventPass(id, { oldPassword, newPassword }).then(() => onDismiss());
  };
  const handleImageSelect = (e) => {
    if (e.target.files && e.target.files[0]) {
      let reader = new FileReader();
      let file = e.target.files[0];
      reader.onloadend = () => {
        seteventData({
          ...eventData,
          image: reader.result,
          file: file,
          croppedImageUrl: null,
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const onSelectCrop = async (props) => {
    seteventData({
      ...eventData,
      croppedImageUrl: props.url,
      file: props.file,
    });

    try {
      const path = "events/" + id + "/eventImage.jpg";
      const response = await helper.uploadPicture(props.file, path);
      eventData.picture = response;
      updateEvent(id, { picture: response }).then(() => onDismiss());
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Card direction="column" style={{ backgroundColor: "#fff" }}>
      <Form onSubmit={handleSubmit(onChangeEvent)}>
        <ImageContainer>
          {eventData.file !== null && eventData.croppedImageUrl === null ? (
            <CropImage src={eventData.image} onSelectCrop={onSelectCrop} />
          ) : (
            <>
              <ImageClickWrapper onClick={handleImageClick}>
                <FontAwesomeIcon
                  style={{ cursor: "pointer" }}
                  icon={faImage}
                  color="white"
                  size="5x"
                  onClick={handleImageClick}
                />
                <Label htmlFor="single">
                  <span>{t("dashboard.selectImage")}</span>
                </Label>
              </ImageClickWrapper>

              <Image
                id="upload-image"
                name="upload-image"
                src={eventData.croppedImageUrl ?? eventData.picture}
                maxWidth={"100%"}
                height={250}
                onClick={handleImageClick}
                onError={(e) => {
                  seteventData({ ...eventData, image: null });
                  e.target.style.display = "none";
                }}
              />
              <ForwardInput ref={ref} />
            </>
          )}
        </ImageContainer>
        <TextInput
          txpe="text"
          placeholder={t("dashboard.name")}
          ref={register({ required: true })}
          name="title"
        />
        {errors.title && (
          <p className="input-error"> {t("dashboard.nameError")}</p>
        )}
        <Textarea
          type="text"
          name="description"
          placeholder={t("dashboard.desc")}
          ref={register}
        />

        <Button
          loading={loading}
          primary
          type="submit"
          disabled={eventData.file && eventData.croppedImageUrl == null}
        >
          {t("editEvent.saveEvent")}
        </Button>
      </Form>
      <Divider />
      <Form onSubmit={handleSubmit2(onChangeEventPass)}>
        {password !== "" && (
          <TextInput
            placeholder={t("editEvent.oldPass")}
            ref={register2}
            name="oldPassword"
            type="password"
            ref={register2({
              required: true,
            })}
          />
        )}

        {errors2.oldPassword && (
          <span className="input-error">{t("editEvent.errorOldPass")}</span>
        )}
        <TextInput
          placeholder={t("editEvent.newPass")}
          ref={register2}
          name="newPassword"
          type="password"
          ref={register2({
            required: true,
            minLength: 8,
            validate: (value) => {
              return (
                value !== watch2("oldPassword") || t("editEvent.oldNewMatch")
              );
            },
          })}
        />
        {errors2.newPassword && (
          <span className="input-error">
            {errors2.newPassword.message && errors2.newPassword.message !== ""
              ? errors2?.newPassword?.message
              : t("register.weakPasswordError")}
          </span>
        )}
        <TextInput
          placeholder={t("editEvent.newPassConf")}
          ref={register2}
          name="newPasswordConf"
          type="password"
          ref={register2({
            required: true,
            validate: (value) => {
              return value === watch2("newPassword");
            },
          })}
        />
        {errors2.newPasswordConf && (
          <span className="input-error">
            {t("register.passwordConfirmError")}
          </span>
        )}
        <Button loading={loading} primary type="submit">
          {t("editEvent.changePassword")}
        </Button>
        <span style={{ marginTop: 10 }} className="input-error">
          {t("editEvent.disclaimer")}
        </span>
      </Form>
    </Card>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.events.actionLoader || false,
    event: state.events.currentEvent,
  };
};

export default connect(mapStateToProps, {
  updateEvent,
  updateEventPass,
})(EditEvent);
