import {
  GETEVENTS_FAIL,
  GETEVENTS_SUCCESS,
  GETEVENT_FAIL,
  GETEVENT_SUCCESS,
  GETEVENT_BEGIN,
  GETEVENTS_BEGIN,
  CREATEEVENT_BEGIN,
  JOINEVENT_BEGIN,
  CREATEEVENT_SUCCESS,
  JOINEVENT_SUCCESS,
  LOGOUT,
  JOINEVENT_FAIL,
  CREATEEVENT_FAIL,
  UPDATEEVENT_BEGIN,
  UPDATEEVENT_SUCCESS,
  UPDATEEVENT_FAIL,
} from "../actions/Types";

const initialState = {
  events: [],
  currentEvent: [],
  loading: true,
  actionLoader: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GETEVENTS_BEGIN:
      return { ...state, loading: false };
    case GETEVENTS_SUCCESS:
      return { ...state, events: payload, loading: false };
    case GETEVENTS_FAIL:
      return { ...state, events: [], loading: false };

    case UPDATEEVENT_BEGIN:
    case JOINEVENT_BEGIN:
    case CREATEEVENT_BEGIN:
      return { ...state, actionLoader: true };
    case JOINEVENT_SUCCESS:
    case CREATEEVENT_SUCCESS:
    case UPDATEEVENT_SUCCESS:
      return { ...state, actionLoader: false };
    case UPDATEEVENT_FAIL:
    case JOINEVENT_FAIL:
    case CREATEEVENT_FAIL:
      return { ...state, actionLoader: false };
    case GETEVENT_BEGIN:
      return { ...state, loading: false };
    case GETEVENT_SUCCESS:
      return { ...state, currentEvent: payload, loading: false };
    case GETEVENT_FAIL:
      return { ...state, currentEvent: {}, loading: false };
    case LOGOUT:
      return { ...initialState };
    default:
      return state;
  }
};
