import React, { useEffect } from "react";
import ProgressiveImage from "../../components/ProgressiveImage/ProgressiveImage";
import Masonry from "react-masonry-css";
import styled from "styled-components";
import NoImages from "../../assets/images/no_images.png";
import Button from "../../components/Button/Button";
import { useTranslation } from "react-i18next";

const Avatar = styled.img`
  height: 40%;
  width: 40%;
`;

const breakpointColumnsObj = {
  default: 6,
  1200: 4,
  1100: 3,
  700: 2,
  500: 1,
};
export const ImageGrid = (props) => {
  const { t } = useTranslation();

  useEffect(() => {
    return () => {};
  }, []);
  return (
    <div style={{ overflow: "hidden" }}>
      {props.images.length > 0 ? (
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {props.images.map((image, index) => {
            return (
              <ProgressiveImage
                onClick={() => props.onClickImage(index)}
                preview={image.preview}
                image={image.normal}
                key={index}
                alt={image.alt}
              />
            );
          })}
        </Masonry>
      ) : (
        <div className="no-event">
          <Avatar src={NoImages} />
          <h3> {t("eventDetails.noImages")}</h3>
          <p style={{ textAlign: "center" }}>
            {t("eventDetails.noImagesDesc")}
          </p>
          <Button primary onClick={props.onShowImageUpload}>
            {t("eventDetails.uploadImages")}
          </Button>
        </div>
      )}
    </div>
  );
};
