export const colors = {
  primaryColor: "#6a69e2",
  primaryDark: "#505078",
  error: "red"
};

export const routes = {
  auth: "/api/auth",
  register: "/api/users",
  event: "/api/events",
  eventJoin: "/api/events/join/",
  user: "/api/users",
  userEvents: "/api/users/events/",
  image: "/api/images"
};
