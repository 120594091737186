import React from "react";
import { connect } from "react-redux";
import { Route, Redirect, withRouter } from "react-router-dom";

const PrivateRoute = ({ component, auth, ...rest }) => {
  let ComponentToRender = component;

  if (auth.isAuthenticated !== null) {
    return (
      <Route
        {...rest}
        render={(props) =>
          auth.isAuthenticated ? (
            <ComponentToRender {...props} />
          ) : (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          )
        }
      />
    );
  } else {
    return null;
  }
};

const mapStateToProps = (state, ownProps) => ({ auth: state.auth });
export default withRouter(connect(mapStateToProps)(PrivateRoute));
