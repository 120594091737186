import helper from "./helper";
import { auth, firestore, functions } from "./firebase";
import i18n from "../services/i18n";
import axios from "axios";

const db = firestore();
const eventsNode = db.collection("events");
const eventHandler = {
  createEventService: async function (eventData) {
    try {
      const ref = eventsNode.doc();
      const eId = ref.id;

      if (eventData.picture) {
        const path = "events/" + eId + "/eventImage.jpg";
        const response = await helper.uploadPicture(eventData.picture, path);
        eventData.picture = response;
      }

      const { data } = await functions().httpsCallable("events-create")({
        eId,
        ...eventData,
      });
      const { event } = data;

      await this.updateEvent(event.id, eventData);
      return event.id;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  updateEvent: async function (eId, eventData) {
    try {
      const shareLink = await this.createShareLink(
        eventData,
        eventData.picture
      );
      return await eventsNode.doc(eId).update({ shareLink: shareLink });
    } catch (error) {
      console.log(error);
    }
  },
  createShareLink: async function (data, picture) {
    var { title, description, eventCode, password } = data;

    try {
      var options = {
        branch_key: "key_live_dfTmaVsEpLnm2DWNiiGFNhkmxrozkHLF",
        data: {
          // $canonical_identifier: "",
          $og_title: title,
          $og_description: description,
          $og_image_url: picture,
          $desktop_url: "http://mymories.de",
          feature: "sharing",
          method: "JoinEvent",
          eventCode: eventCode,
          hash: password ? password : "noauth",
        },
      };
      let result = await axios.post("https://api2.branch.io/v1/url", options, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      let { url } = result?.data;
      return url;
    } catch (error) {
      console.log(error);
      return null;
    }
  },
  checkForEventCode: function (userResponse, code) {
    const eCode = code;
    return new Promise((resolve, reject) => {
      let eventCollection = db
        .collection("events")
        .where("eventCode", "==", eCode);

      eventCollection.get().then((event) => {
        if (event.docs.length > 0) {
          event.docs.map((doc) => {
            const data = doc.data();
            const id = doc.id;

            resolve({ user: userResponse, id, data });
          });
        } else {
          resolve({
            status: "error",
            message: i18n.t("eventReducer.eventNotFoundFailure"),
            code: eCode,
          });
        }
      });
    });
  },
  createEvent: function (eventData, image) {
    return new Promise((resolve, reject) => {
      eventsNode
        .add(eventData)
        .then((data) => {
          let userRef = db.collection("users").doc(eventData.adminId);
          eventsNode
            .doc(data.id)
            .collection("eUser")
            .add({
              userRef: userRef,
              isAdmin: true,
              notifications: true,
            })
            .then(() => {
              db.collection("users")
                .doc(eventData.adminId)
                .collection("uEvents")
                .add({
                  eventRef: db.collection("events").doc(data.id),
                  visited: false,
                })
                .then((response) => {
                  resolve({ data, image, eventData });
                });
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  joinEventById: function (eCode, hash) {
    return new Promise((resolve, reject) => {
      this.checkForAuth()
        .then((response) => {
          return this.checkForEventCode(response, eCode);
        })
        .then((response) => {
          if (response.status === "error") {
            reject(response.message);
          } else {
            return this.checkJoinValid(
              response.user.uid,
              response.id,
              response.data,
              hash
            );
          }
        })
        .then((response) => {
          return this.joinEvent(
            response.uid,
            response.eUserCollection,
            response.event,
            response.eId
          );
        })
        .then((eId) => {
          resolve(eId);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  checkForAuth: function () {
    return new Promise((resolve, reject) => {
      var currentUser = auth().currentUser;
      if (currentUser !== null) {
        resolve(currentUser);
      } else {
        auth()
          .signInAnonymously()
          .then((response) => {
            let user = response.user;
            const userRef = firestore().collection("users").doc(user.uid);
            userRef
              .set({
                uid: user.uid,
                email: "anonym@mymories.de",
                fullName: "Adam Anonymouse",
                picture: "noPicture",
                method: "join",
                provider: "anoynm",
              })
              .then(() => {
                resolve(response.user);
              })
              .catch((error) => {
                reject(error);
              });
          });
      }
    });
  },
  checkJoinValid: function (uid, eId, eData, hash) {
    return new Promise((resolve, reject) => {
      if (hash === eData.password) {
        let event = eventsNode.doc(eId);
        let eUserCollection = event.collection("eUser");
        let userRef = db.collection("users").doc(uid);
        let check = eUserCollection.where("userRef", "==", userRef);
        check
          .get()
          .then((event) => {
            return event.docs.map((doc) => {
              const data = doc.data();
              const id = doc.id;
              return { id, data };
            });
          })
          .then((user) => {
            if (user.length > 0) {
              reject("User bereits im Event");
            } else {
              resolve({ uid, eUserCollection, event, eId });
            }
          });
      } else {
        reject("Das Passwort für das Event ist Falsch");
      }
    });
  },
  joinEvent: function (userId, eUserCollection, event, eId) {
    return new Promise((resolve, reject) => {
      let userRef = db.doc("users/" + userId);
      eUserCollection
        .add({
          userRef: userRef,
          isAdmin: false,
        })
        .then(() => {
          db.collection("users")
            .doc(userId)
            .collection("uEvents")
            .add({
              eventRef: db.doc("events/" + eId),
              visited: false,
              notifications: true,
            })
            .then(() => {
              event.get().then((doc) => {
                resolve(eId);
              });
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deletePicture: async function (isAdmin, eventId, userId, imageId) {
    try {
      let doc = await eventsNode
        .doc(eventId)
        .collection("images")
        .doc(imageId)
        .get();

      doc.delete();
    } catch (error) {}
  },
};

export default eventHandler;
