import {} from "../actions/Types";

const initialState = {
  images: [],
  queue: [],
  loading: true,
};

export default (state = initialState, { type, payload }) => {
  return state;
};
