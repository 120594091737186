import React from "react";
import styled, { css } from "styled-components";
import { colors } from "../../presets/index";

const Divi = styled.div`
  width: ${props => (props.width ? props.width : "100%")};
  height: ${props => (props.height ? props.height : "1px")};
  background-color: #ccc;
  margin: ${props => (props.marginVert ? props.marginVert : 0)}
    ${props => (props.marginHor ? props.marginHor : 0)};
  ${props =>
    props.primary &&
    css`
      background-color: ${colors.primaryColor};
    `}
`;

export const Divider = props => {
  return <Divi {...props} />;
};
