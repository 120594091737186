import { SET_ALERT, REMOVE_ALERT } from "./Types";
import uuid4 from "uuid4";
export const setAlert = (header, msg, alertType) => (dispatch) => {
  const id = uuid4();
  dispatch({
    type: SET_ALERT,
    payload: { header, msg, alertType, id },
  });

  setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), 4000);
};

export const removeAlert = (id) => (dispatch) => {
  dispatch({ type: REMOVE_ALERT, payload: id });
};
