import React from "react";
import SquareLoader from "react-spinners/SquareLoader";
import { colors } from "./../../presets/index";
import styled from "styled-components";

const Wrapper = styled.div`
  z-index: 1000;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: ${props => (props.bgcolor ? props.bgcolor : "#fff")};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoaderWrapper = props => {
  if (props.active) {
    return (
      <Wrapper {...props}>
        <SquareLoader color={props.color ? props.color : colors.primaryColor} />
      </Wrapper>
    );
  } else {
    return null;
  }
};
