import React, { Component } from "react";
import { connect } from "react-redux";
import PulseLoader from "react-spinners/PulseLoader";
import styled from "styled-components";
import { getEvents } from "../actions/event";
import Empty from "../assets/images/empty.png";
import Button from "../components/Button/Button";
import CreateEvent from "../components/CreateEvent/CreateEvent";
import Navbar from "../components/Navbar/Navbar";
import OpaccityWrapper from "../components/OpaccityWrapper/OpaccityWrapper";
import history from "../history";
import { EventCard } from "./../components/Card/EventCards";
import { colors } from "./../presets/index";
import { firestore } from "./../utils/firebase";
import { withTranslation } from "react-i18next";
import ReactGA from "react-ga4";

const Avatar = styled.img`
  height: 30%;
  width: 30%;
  margin-bottom: 25px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
  width: 75%;

  > button {
    width: 48%;
  }
`;

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      method: "create",
      events: props.events || [],
      oppaccity: false,
      isLoading: props.events?.loading || true,
    };
    this.unsubscribe = null;
  }

  componentDidMount() {
    ReactGA.send({ hitType: "pageview", page: this.props.path });

    let ref = firestore()
      .collection("users")
      .doc(this.props.user.uid)
      .collection("uEvents");
    this.unsubscribe = ref.onSnapshot((snapshot) => {
      this.props.getEvents(this.state.events, snapshot);
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.events !== this.props.events) {
      this.setState({
        events: this.props.events,
        isLoading: this.props.isLoading,
      });
    }
  }
  createEvent = (method) => {
    this.setState({ opaccity: true, method });
  };

  dismissEvent = () => {
    this.setState({ opaccity: false });
  };

  removeWrapper = (event) => {
    if (event.target.id === "overlay" || event.target.id === "wrapper") {
      this.setState({ opaccity: false });
    }
  };

  goToEvent = (event) => {
    history.push(`/event/${event.id}`);
  };
  render() {
    const t = this.props.t;
    var { isLoading } = this.state;
    var isEmpty = this.state.events && this.state.events.length > 0;

    return (
      <div className="wrapper">
        {this.state.opaccity ? (
          <OpaccityWrapper onClick={this.removeWrapper}>
            <CreateEvent
              tab={this.state.method}
              onDismiss={this.dismissEvent}
            />
          </OpaccityWrapper>
        ) : null}
        <Navbar
          item={[
            {
              key: "add",
              text: t("dashboard.newEvent"),
              icon: "add",
              onClick: () => this.createEvent("create"),
            },
          ]}
        />
        <div className={`content ${!isEmpty ? "flex-center" : null}`}>
          {isLoading ? (
            <div className="loader-wrapper">
              <PulseLoader color={colors.primaryColor} size={15} />
            </div>
          ) : (
            <div className="container">
              {isEmpty ? (
                <div className="events-wrapper">
                  <div className="grid-row">
                    {this.state.events.map((event, index) => {
                      return (
                        <EventCard
                          className="grid-item"
                          key={index}
                          data={event}
                          onClick={() => this.goToEvent(event)}
                        />
                      );
                    })}
                  </div>
                </div>
              ) : (
                <div className="no-event">
                  <Avatar src={Empty} />
                  <h3>{t("dashboard.createFirstEvent")}</h3>
                  <p style={{ textAlign: "center" }}>
                    {t("dashboard.createFirstEventDesc")}
                  </p>
                  <ButtonWrapper>
                    <Button primary onClick={() => this.createEvent("create")}>
                      {t("dashboard.createEvent")}
                    </Button>
                    <Button
                      primary
                      outline
                      onClick={() => this.createEvent("join")}
                    >
                      {t("dashboard.joinEvent")}
                    </Button>
                  </ButtonWrapper>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  if (state.auth && state.auth.user) {
    return {
      events: state.events.events,
      user: state.auth.user,
      isLoading: state.events.loading,
    };
  }
};
export default connect(mapStateToProps, {
  getEvents,
})(withTranslation()(Dashboard));
