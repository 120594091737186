import React from "react";
import styled from "styled-components";
import { colors } from "./../../presets/index";
import Button from "./../Button/Button";
import OppacityWrapper from "./../OpaccityWrapper/OpaccityWrapper";
import QRCode from "react-qr-code";
import { TextInput } from "./../TextInput.js/TextInput";
import { faCopy } from "@fortawesome/free-solid-svg-icons";

const Wrapper = styled.div`
  background-color: #fff;
  padding: 24px 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const P = styled.p`
  width: 60%;
`;

const Code = styled.p`
  color: ${colors.primaryColor};
  font-size: 30px;
  font-weight: bold;
`;

const ShareEvent = (props) => {
  var data = props.data || { qr: {}, link: null };
  var txtRef = React.useRef();

  const copyLink = (e) => {
    txtRef.select();
    document.execCommand("copy");
  };

  return (
    <OppacityWrapper onClick={props.closeWrapper}>
      <Wrapper>
        <h2>Event teilen</h2>
        <P>
          Um diesem Event beizutreten kannst du anderen Nutzer den Eventcode
          geben
        </P>
        <Code>{data.qr.eventCode}</Code>
        <P>Alternativ kannst du diese auch per QR-Code oder Link einladen</P>
        <TextInput
          style={{ width: "60%", marginBottom: 15 }}
          readOnly
          value={data.link}
          icon={faCopy}
          onClick={copyLink}
          ref={(ref) => (txtRef = ref)}
        />
        <QRCode value={JSON.stringify(data.qr)} />
        <Button primary onClick={props.closeShare} style={{ marginTop: 15 }}>
          Zurück
        </Button>
      </Wrapper>
    </OppacityWrapper>
  );
};

export default ShareEvent;
