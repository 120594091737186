import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_ALERT,
  LOGIN_BEGIN,
  REGISTER_BEGIN,
} from "./Types";
import { setAlert } from "./alert";
import { auth, firestore } from "./../utils/firebase";
import helper from "../utils/helper";
import i18n from "../services/i18n";

// Load User
export const loadUser = (user) => async (dispatch) => {
  const userRef = await firestore().collection("users").doc(user.uid).get();

  if (userRef.exists) {
    var data = await userRef.data();
    dispatch({
      type: USER_LOADED,
      payload: data,
    });
  } else {
    dispatch({
      type: LOGIN_FAIL,
    });
    setAlert(
      i18n.t("error.loginFailed"),
      i18n.t("error.errorUnknown"),
      "error"
    );
  }
};

export const updateUser = (data) => async (dispatch) => {
  try {
    var { uid, ...updateData } = data;
    const userRef = await firestore().collection("users").doc(uid);
    await userRef.update({ ...updateData });
    let userData = await userRef.get();
    dispatch({
      type: USER_LOADED,
      payload: userData.data(),
    });
    dispatch(
      setAlert(
        i18n.t("authReducer.titleSaveProfilSuccess"),
        i18n.t("authReducer.messageSaveProfilSuccess"),
        "success"
      )
    );
  } catch (err) {
    console.log(err);
    var errMsg = helper.getFirebaseError(err.message, err.code);
    dispatch(
      setAlert(i18n.t("authReducer.titleSaveProfilFailure"), errMsg, "error")
    );
  }
};

//social login

export const socialLogin = (provider) => async (dispatch) => {
  dispatch({
    type: REGISTER_BEGIN,
  });
  try {
    const result = await auth().signInWithPopup(provider);
    const { user } = result;

    const { uid, email, displayName, photoURL, providerData } = user;
    let userData = {
      uid: uid,
      email: email,
      fullName: displayName ?? "Anonymous",
      picture: photoURL ?? "noPicture",
      lastLogin: Date.now(),
      provider: providerData[0].providerId,
    };

    userData = Object.keys(userData)
      .filter((key) => userData[key] !== null)
      .reduce((a, k) => ({ ...a, [k]: userData[k] }), {});

    const userRef = await firestore().collection("users").doc(user.uid);

    if (userRef.exists) {
      userRef.update({
        ...userData,
      });
    } else {
      await userRef.set({
        ...userData,
        created: Date.now(),
      });
    }
    dispatch({
      type: REGISTER_SUCCESS,
      payload: user,
    });
  } catch (err) {
    console.log(err);
    var errMsg = helper.getFirebaseError(err.message, err.code);
    dispatch(
      setAlert(i18n.t("authReducer.titleRegisterFailure"), errMsg, "error")
    );

    dispatch({
      type: REGISTER_FAIL,
    });
  }
};

//Register User
export const register = ({ name, email, password, profilImage }) => async (
  dispatch
) => {
  dispatch({
    type: REGISTER_BEGIN,
  });
  try {
    let result = await auth().createUserWithEmailAndPassword(email, password);

    var { user } = result;
    const userRef = await firestore().collection("users").doc(user.uid);

    await userRef.set({
      uid: user.uid,
      email: email,
      fullName: name,
      picture: "noPicture",
      created: Date.now(),
      lastLogin: Date.now(),
    });

    user.fullName = name;

    //To Do
    if (profilImage) {
      let path = "users/" + user.uid + "/profil.jpg";
      let response = await helper.uploadImageAsync(
        this.state.profilImage,
        path
      );
      await userRef.update({ picture: response });
      user.picture = response;
    }
    dispatch({
      type: REGISTER_SUCCESS,
      payload: user,
    });
  } catch (err) {
    console.log(err);
    var errMsg = helper.getFirebaseError(err.message, err.code);
    dispatch(
      setAlert(i18n.t("authReducer.titleRegisterFailure"), errMsg, "error")
    );

    dispatch({
      type: REGISTER_FAIL,
    });
  }
};

//Login User
export const login = (email, password) => async (dispatch) => {
  dispatch({
    type: LOGIN_BEGIN,
  });
  try {
    const result = await auth().signInWithEmailAndPassword(email, password);

    var { user } = result;
    const userRef = await firestore().collection("users").doc(user.uid).get();
    user.picture = userRef.data().picture;
    dispatch({
      type: LOGIN_SUCCESS,
      payload: user,
    });

    // dispatch(loadUser());
  } catch (err) {
    console.log(err);
    var errMsg = helper.getFirebaseError(err.message, err.code);

    dispatch(setAlert(i18n.t("error.loginFailed"), errMsg, "error"));

    dispatch({
      type: LOGIN_FAIL,
    });
  }
};

// Logout
export const logout = () => async (dispatch) => {
  await auth().signOut();
  dispatch({
    type: LOGOUT,
  });
};
