import React, { Component } from "react";
import styled from "styled-components";
import FileUpload from "../FileUpload/FileUpload";
import OppacityWrapper from "../OpaccityWrapper/OpaccityWrapper";

const CardHeader = styled.h2`
  text-align: center;
  padding: 16px 0;
  margin: 0px !important;
  box-shadow: inset 0 -1px 0 0 #efefef;
`;
class AddImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageCallback: props.imageCallback,
      images: props.images,
      selected: {},
      dragging: false,
    };
    this.dropRef = React.createRef();
    this.fileUploadRef = null;
  }

  handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter++;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.setState({ dragging: true });
    }
  };
  handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter--;
    if (this.dragCounter > 0) return;
    this.setState({ dragging: false });
  };

  handleDrop = () => {
    // e.preventDefault()
    // e.stopPropagation()
    this.setState({ dragging: false });
    // if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
    //   this.dragCounter = 0
    // }
  };

  componentDidMount() {
    this.dragCounter = 0;
    let div = this.dropRef.current;
    div.addEventListener("dragenter", this.handleDragIn);
    div.addEventListener("dragleave", this.handleDragOut);
    div.addEventListener("dragover", this.handleDrag);
  }

  componentWillUnmount() {
    let div = this.dropRef.current;
    div.removeEventListener("dragenter", this.handleDragIn);
    div.removeEventListener("dragleave", this.handleDragOut);
    div.removeEventListener("dragover", this.handleDrag);
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  selectImage = (image) => {
    this.setState({ selected: image });
  };

  closeUpload = () => {
    this.state.imageCallback(null);
  };
  uploadFiles = (files) => {
    this.state.imageCallback(files);
  };

  clickWrapper = (event) => {
    if (event.target.id === "overlay" || event.target.id === "wrapper") {
      this.state.imageCallback(null);
    }
  };

  clearImages = () => {
    this.fileUploadRef && this.fileUploadRef.filesRemoveAll();
  };
  render() {
    return (
      <OppacityWrapper onClick={this.clickWrapper}>
        <div class="ImgCard" ref={this.dropRef}>
          <CardHeader className="SectionTitleLeftLarge">
            Bilder Hochladen
          </CardHeader>
          <FileUpload
            uploading={this.props.uploading}
            ref={(ref) => (this.fileUploadRef = ref)}
            onDrop={this.handleDrop}
            closeUpload={this.closeUpload}
            uploadFiles={this.uploadFiles}
            className={this.state.dragging ? "dragActive" : ""}
          />
        </div>
      </OppacityWrapper>
    );
  }
}

export default AddImage;
