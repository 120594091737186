import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "semantic-ui-react";
import Button from "../Button/Button";
import { TextInput } from "../TextInput.js/TextInput";
import { useForm } from "react-hook-form";

import "./LostPassword.scss";
import { auth } from "../../utils/firebase";
import { setAlert } from "../../actions/alert";
import helper from "../../utils/helper";
import { connect } from "react-redux";

const LostPassword = ({ open, close, setAlert }) => {
  const { t } = useTranslation();
  const { register, handleSubmit, errors } = useForm();
  const [isLoading, setLoading] = useState(false);
  const [hasError, setError] = useState("");

  const resetPassword = async ({ email }) => {
    setLoading(true);

    try {
      await auth().sendPasswordResetEmail(email);
      setLoading(false);
      close();
      setAlert(
        t("forgottPassword.titleResetSuccess"),
        t("forgottPassword.messageResetSuccess"),
        "success"
      );
    } catch (error) {
      setLoading(false);
      let message = helper.getFirebaseError(error.message, error.code);
      setError(message);
    }
  };

  return (
    <Modal id="password-modal" size={"tiny"} open={open}>
      <Modal.Header>{t("forgottPassword.title")}</Modal.Header>
      <Modal.Content>
        <p>{t("forgottPassword.content")}</p>

        <form>
          <TextInput
            className="modal-input"
            name="email"
            placeholder={t("forgottPassword.email")}
            ref={register({
              required: true,
              pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            })}
          />
          {errors.email && (
            <div>
              <span className="input-error">{t("login.emailError")}</span>
            </div>
          )}
          {hasError && (
            <div>
              <span className="hasError">{hasError}</span>
            </div>
          )}
        </form>
      </Modal.Content>
      <Modal.Actions className="modal-actions">
        <Button
          className="modal-button"
          primary
          loading={isLoading}
          onClick={handleSubmit(resetPassword)}
        >
          {t("forgottPassword.buttonReset")}
        </Button>
        <Button outline onClick={close}>
          {t("forgottPassword.cancel")}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default connect(null, {
  setAlert,
})(LostPassword);
